
[notifications].content--narrow { height: 100%;
}
[notifications] [data-icon="check-circle"], [notifications] [data-icon="times-circle"] { height: 1.25rem;
}
[notifications] .hide { opacity: 0; pointer-events:none; position: absolute; top: -1000rem;
}
[notifications="detail"] { position: absolute; top: 0; --zg-row-body-background_hover: transparent;
}
[notifications="gridContainer"] { position: relative;
}
[notifications="list"] { position: absolute; top: 0;
}
[notifications="list"] [data-field-index="needs_response"] { text-align: center;
}
[notifications="list"] .read { background-color: #f4f4f5; color: #5f5f5f;
}
