
p[data-v-2e0ebbd4] {
    bottom: 1.4rem;
    color: var(--color-tertiary-blue-visited);
    color: var(--color-tertiary-blue-visited);
    font-size: 0.8125rem;
    font-weight: 500;
    margin: 0 1rem;
    max-width: 25%;
    position: relative;
    text-align: center;
}
.scorecard-group[data-v-2e0ebbd4] {
    align-items: center;
    display: flex;
    width: 100%;
}
.scorecard-group[data-v-2e0ebbd4] > * {
    flex: 1;
}
