
.filter_wrapper [icon="close--circle"] svg {
    position: relative;
    top: -6px;
}
[class*="filter_prefix"] .el-input__prefix {
    color: #C0C4CC;
    font-size: 0.75rem;
    left: 15px;
    line-height: 30px;
}
.filter_wrapper .el-input input {
    height: 30px !important;
}

  /* metadata */
.filter_prefix--metadata .el-input input {
    padding-left: 7.75rem;
}

  /* metadata value */
.filter_prefix--metadataValue .el-select__tags {
    margin-left: 9.5rem;
}

  /* Scrollable filter */
[scrollable] .el-select__tags {
    flex-wrap: nowrap;
    max-height: 29px;
    overflow: auto;
    width: calc(100% - 12rem) !important;
}
.angle--right {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
