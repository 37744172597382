
.demo__dummy-text {
    text-align: center;
}
.plan-dialog {
    background: #EEE !important;
    max-width: 900px;
    width: 90% !important;
    overflow: hidden;
}
.el-form-item button.hide {
    display: none;
}
.delete-field {
    margin-top: 2rem;
}

  /* Subscription List */
.subscription__list {
    display: flex;
    flex-wrap: wrap;
}

  /* DOMAIN ITEM */
.domain__item {
    display: flex;
    justify-content: space-between;
}
.domain__url {
    font-weight: 500px;
    font-size: 0.9rem;
}
.domain__add .el-form-item {
    flex-shrink: 0;
    flex-grow: 1;
}
.domain__delete {
    color: var(--link-color);
    color: var(--link-color);
    cursor: pointer;
}
.plan__coupon_add {
    margin-bottom: 1rem;
}
.plan__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    padding: 1rem 0;
}
.plan__list__created {
    flex: 1;
    margin: 0 4rem;
    text-align: center;
    word-break: break-word;
}
.plan-tabs {
    display: flex;
    font-size: 0.7rem;
    margin-right: 1px;
}
.plan-tab {
    border-bottom: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
    flex: 1;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--background-dark);
    background: var(--background-dark);
    padding: 0.5rem;
    cursor: pointer;
}
.plan-tab + .plan-tab {
    border-left: 1px solid var(--border-light);
    border-left: 1px solid var(--border-light);
}
.plan-tab--inactive {
    background: white;
    color: #606266;
}
.plan-tab--inactive:hover {
    background: var(--color-primary-5);
    background: var(--color-primary-5);
    color: white;
}
.plans {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
}
.plan-company {
    flex: 1;
    width: 100%;
}
.plan-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 1rem;
    background: #FFF;
}
.plan-content__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    border-bottom: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
}
.plan-content__title {
    font-size: 1.2rem;
}
.plan-content__price {
    font-size: 1.2rem;
    font-weight: 600;
}
.plan-features {
    flex: 1;
}
.plan-feature {
    list-style-type: none;
}
.plan-feature svg {
    color: var(--color-success);
    color: var(--color-success);
}
.plan-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
  

  /* Subscription Cards */
.subscription__card {
    width: 300px;
    height: 375px;
    border: 1px solid var(--border-light);
    border: 1px solid var(--border-light);
    background: #FFF;
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
}
.subscription__header {
    display: flex;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
    background: var(--color-greyscale-2);
    background: var(--color-greyscale-2);
    color: white;
    justify-content: space-between;
    align-items: center;
}
.subscription__oem {
    padding: 0 0.5rem;
    overflow-y: scroll;
    max-height: 10.5rem;
}
.subscription__footer {
    align-items: flex-end;
    display: flex;
    flex: 1;
}
.subscription__footer__btn-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.subscription__footer__btn-container > .el-button {
    flex: 1;
}
.subscription__section {
    max-height: 182px;
    padding: 0.5rem;
}
.subscription__top, .subscription__domain-count {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.8rem;
    color: var(--color-greyscale-2);
    color: var(--color-greyscale-2);
}
.subscription__name {
      margin: 0;
}
.subscription__domain-heading, .subscription__domain-count {
    color: var(--color-greyscale-2);
    color: var(--color-greyscale-2);
    margin: 0;
}
.subscription__domain-heading > .is-link {
    padding: 0;
}
.domain__list {
    padding: 0 0 0.5rem;
    margin: 0;
    max-height: 140px;
    overflow-y: auto;
}
.domain__list:last-of-type {
    padding: 0;
}
.domain__item {
    padding: 0.05rem 0.75rem;
}
.domain__url {
    font-weight: 500;
    color: var(--color-primary-1);
    color: var(--color-primary-1);
}
.domain__add {
    display: flex;
}
.domain__item--add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.dialog__controls {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
}
.unsubscribe-controls .el-button {
    margin-top: 1rem;
}
.dialog .el-dialog__body > * {
    text-align: center;
}
.dialog__icon {
    text-align: center;
}
.max-width-dialog {
    max-width: 40rem;
}
.subscription-info {
    padding: 0 40px;
    word-break: break-word;
}
.subscription-info__item {
    text-align: left;
}
.subscription__svg {
    height: 2.5rem;
    position: relative;
    top: 5px;
}
.subscription__svg__zg {
    height: 1.6rem;
}
@media screen and (min-width: 800px) {
.plans {
      flex-direction: row;
}
.plan-tabs {
      border: 0;
      flex-direction: column;
      font-size: 1rem;
      width: 170px;
}
.plan-tab:not(:last-child) {
      border-bottom:1px solid var(--border-light);
      border-bottom:1px solid var(--border-light);
}
}
@media screen and (min-width: 512px) {
.plan-company {
      width: 60%;
}
}
@media screen and (min-width: 960px) {
.subscription__card {
      margin: 0 1rem 1rem 0;
}
}
header .toggle span {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 12px;
}
header label {
    font-size: 1rem;
}
.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.plan-dialog .el-dialog {
    background: #E6E6E6;
    max-width: 900px;
    padding-right: 0 !important;
}
.plan-dialog .el-dialog__header {
    background: var(--background-dark);
    background: var(--background-dark);
}
.plan-dialog .el-dialog__body {
    min-height: 500px;
    padding: 0;
}
.plan-dialog.small .el-dialog__body {
    min-height: 205px;
}
.plan-dialog .el-dialog__title {
    color: white;
}
.plan-dialog__content {
    display: flex;
    justify-content: center;
    min-height: 400px;
    padding: 0 2rem;
    word-break: break-word;
}
.plan-dialog .el-steps.el-steps--horizontal {
      background: #FFF;
      padding: 1rem;
}
.el-step__head is-process .el-step__icon {
    background: #FFF;
}
.plan-dialog .el-step__head, .plan-dialog .el-step__head.is-process, .plan-dialog .el-step__title, .plan-dialog .el-step__title.is-process {
    color: var(--color-tertiary-6);
    color: var(--color-tertiary-6);
    border-color: var(--color-tertiary-6);
    border-color: var(--color-tertiary-6);
}
.plan-dialog .el-step__title {
    color: var(--color-tertiary-6);
    color: var(--color-tertiary-6);
    display: none;
}
.plan-dialog .el-switch__label * {
    font-size: 1;
}
.plan__list .el-switch {
    --el-switch-on-color: #2196f3;
    --el-switch-off-color: var(--background-dark);
    --el-switch-off-color: var(--background-dark);
}
.renew-dialog .el-dialog {
    max-width: 45rem;
}
.renew-dialog .el-dialog__title {
    padding: 2rem 1rem !important;
}
.renew-dialog .el-dialog__body {
    padding: 0 0 20px 25px !important;
}
.dialog .el-dialog__body {
    padding: 0 30px 20px;
}
[subscription="cardDialog"] .el-dialog__body {
    padding: 0 48px 40px;
}
.invoice-dialog .el-form-item__content {
    display: block;
    text-align: left;
}
@media screen and (min-width: 600px) {
.plan-dialog .el-step__title {
      display: block;
}
}
