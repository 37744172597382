
  /* Dialog Overwrites */
[toggle-grid] .dialog--condense { display: flex; justify-content: center;
}
[toggle-grid] .dialog--condense .el-dialog { padding-right: 0 !important;
}
[toggle-grid] .dialog--condense .el-dialog__body { padding: 0 30px 2rem !important;
}
[toggle-grid] .dialog--condense .el-dialog__body > *, .dialog__icon { text-align: center;
}
[toggle-grid] .dialog--condense  .el-dialog__header { padding: 1rem 0rem !important;
}
[toggle-grid] .dialog__controls { display: flex; justify-content: center; flex-wrap: wrap; margin-top: 2rem; width: 100%;
}
[toggle-grid] .dialog__delete { background: #f3dede; border-radius: 8px; margin: 2rem; padding: 1rem;
}

  /* Grid */
[toggle-grid][heading="2"] zg-caption { font-size: 1.5rem;
}
[toggle-grid] zg-caption .el-button { float: right; margin: 0 0.5rem 0 0;
}
[toggle-grid] + h1,
  [toggle-grid][hidden], [noHide="false"][hidden] { position: absolute; opacity: 0; pointer-events: none;
}
[toggle-grid][hidden] + h1, [noHide="true"][hidden]  { position: relative; opacity: 1; pointer-events: all;
}
[noHide="true"][hidden] { display: block;
}

  /* List ZingGrid */
[toggle-grid].hide { opacity: 0; pointer-events: none;
}
[toggle-grid="container"] { position: relative;
}

  /* Details ZingGrid */
[hideList=true] [toggle-grid="list"] + [toggle-grid="details"] { margin-top: 7rem;
}
[toggle-grid="details"].hide { display: none;
}
[toggle-grid="details"].main { display: block; position: relative;
}
[toggle-grid="details"] zg-row.disabled zg-cell:not([data-field-index="id, name, active"]) { opacity: 0.5; pointer-events: none;
}
[toggle-grid="details"] zg-cell[value="false"] { color: rgb(255, 73, 73);
}
[toggle-grid="details"] zg-cell[value="true"] { color: rgb(19, 206, 102);
}
[toggle-grid="list"] + [toggle-grid="details"] { margin: 0;
}

  /* Toggle Switches */
[toggle-grid="switch"] { bottom: 2px; margin-left: 1rem;
}
[toggle-grid] .el-switch__core { background-color: rgb(255, 73, 73);  border-color: rgb(255, 73, 73); width: 40px;
}
[toggle-grid] .el-switch.is-checked .el-switch__core { background-color: rgb(19, 206, 102); border-color: rgb(19, 206, 102);
}
[toggle-grid] .is-disabled .el-switch__core, [toggle-grid] .el-switch.is-checked.is-disabled .el-switch__core { background-color: rgb(132, 132, 132); border-color: rgb(132, 132, 132);
}
  /* Expandable Row Styles */
[toggle-grid="list"] zg-row:hover { background-color:#f5f7fa; border-bottom:1px solid #ebebeb; transition:background-color 0.25s ease-in;
}

  /* Tooltips */
.tooltip { position: relative;
}
.tooltip__trigger:hover + .tooltip__message { opacity: 1; pointer-events: all;
}
.tooltip__message { background-color: #303133; border-radius: 5px; color: #fff; font-weight: 500; opacity: 0; padding: 0.5rem 1rem; pointer-events: none; position: absolute; right: 0; text-align: center; width: 15rem; top:-1rem; z-index: 100;
}
.tooltip__message:after {  border-top: solid 8px transparent; border-right: solid 10px #303133; border-bottom: solid 8px transparent; content: ''; position: absolute; left: -0.5rem; top: 1rem;
}

  /* Theme */
[toggle-grid] zg-caption {
    background: transparent;
    border-bottom: 1px solid #DDD;
    color: #6A848F;
    font-family: var(--font-family);
    font-family: var(--font-family);
    font-size: 1.5625rem;
    font-weight: lighter;
    height: 3.25rem;
    letter-spacing: 0.05rem;
    margin: 2rem 0 1rem;
    padding: 0;
}
[toggle-grid="details"] {
    position: absolute;
    top: 0;
}
zing-grid[toggle-grid]{
    background: transparent;
    border: 1px solid #dcdfe6;
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
    margin-top: 7rem;
}
[toggle-grid] zg-head-cell {
    background-color: #f5f7fa;
}
[toggle-grid] zg-header {
    position: absolute;
    top: -7rem;
    width: 100%;
}
