
  /* GLOBAL */
:root {
    --nav-height: 3.125rem;
    --sidebar-primary-width: 3.25rem;
    --sidebar-secondary-width: 13.4375rem;
    --sidebar-item-height: 1.375rem;
    --sidebar-item-hover: #E2F4FA;
    --sidebar-item-container-height: 3.1875rem;
    --sidebar-top-item-color: #286D8B;
}

  /* TRANSITIONS */
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

  /* SIDEBAR */
.real {
    height: 100vh;
    position: fixed;
    z-index: 1000;
}
.sidebar-container { 
    display: flex;
}
.sidebar-container[display="none"] { 
    display: none;
}
.sidebar--primary {
    background: var(--color-primary-6);
    background: var(--color-primary-6);
    width: 3.25rem;
    width: var(--sidebar-primary-width);
}
.sidebar--secondary {
    background: #fff;
    overflow: hidden;
    width: 0;
}
.sidebar--secondary.real {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.23922);
    overflow-y: auto;
}
.transition .sidebar--secondary {
    transition: width 0.25s ease-in;
}
.sidebar--secondary.real {
    margin-left: 3.25rem;
    margin-left: var(--sidebar-primary-width);
    padding-bottom: 8rem;
}
[secondary-sidebar="open"] .sidebar--secondary,
  .sidebar--secondary.open {
    width: 13.4375rem;
    width: var(--sidebar-secondary-width);
}
.sidebar--tertiary {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s ease-in;
}
.sidebar-item--secondary.active.collapse .sidebar--tertiary {
    max-height: 0;
}
.sidebar--top {
    height: auto;
}
.sidebar--top a { color: var(--color-primary-7); color: var(--color-primary-7);
}
.sidebar-item--secondary.active .sidebar--tertiary {
    max-height: 100rem;
}
.sidebar__link {
    text-decoration: none;
}
.sidebar__hr {
    border-style: solid;
    border-top: 0;
    color: var(--color-greyscale-11);
    color: var(--color-greyscale-11);
    margin: 0 1.65rem 0;
}

  /* SIDEBAR ITEM */
[sidebar-id^="na"] {
    pointer-events: none;
}
.sidebar-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    height: 3.1875rem;
    height: var(--sidebar-item-container-height);
    width: 13.4375rem;
    width: var(--sidebar-secondary-width);
}
.sidebar-item--primary {
    align-content: center;
    background: var(--color-primary-6);
    background: var(--color-primary-6);
    display: flex;
    justify-content: center;
    padding: 0;
    width: 3.25rem;
    width: var(--sidebar-primary-width);
    transition: background 0.25s cubic-bezier(0.47, 0, 0.75, 0.72);
}
.sidebar-item--primary.active {
    background: var(--color-tertiary-7);
    background: var(--color-tertiary-7);
}
.sidebar-item--primary:not(.active):hover {
    background: #294D61;
}
.sidebar-item--secondaryNav {
    height: auto;
}
.sidebar-item--secondary:not(.active):hover {
    background: #E2F4FA;
    background: var(--sidebar-item-hover);
}
.sidebar-item--flex {
    display: flex;
    width: 100%;
}
.sidebar-item--tertiary {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.625rem;
    padding: 0px 1.625rem 0 3.3rem;
    text-indent: -1.8rem;
}
.sidebar-item--tertiary:hover {
    background: #E2F4FA;
    background: var(--sidebar-item-hover);
}
.active .sidebar-item--tertiary {
    color: var(--color-primary-7);
    color: var(--color-primary-7);
}
.sidebar-item__svg--tertiary {
    margin-right: 0.6875rem;
    position: relative;
    top: 2px;
}
.sidebar-item--top {
    font-weight: normal;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}
.sidebar-item__angle {
    bottom: 3px;
    margin: 0 0.625rem 0 auto;
    position: relative;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.sidebar-item__angle--secondary,
  .sidebar-item--secondary.active.collapse .sidebar-item__angle--secondary {
    position: absolute;
    bottom: 0.90rem;
    left: 0.65rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    transition: -webkit-transform 0.25s ease-in;
    transition: transform 0.25s ease-in;
    transition: transform 0.25s ease-in, -webkit-transform 0.25s ease-in;
}
.sidebar-item--secondary.active .sidebar-item__angle--secondary {
    bottom: 0.90rem;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.sidebar-item__count {
    background-color: #D6F2FF;
    border-radius: 20px;
    color: var(--color-tertiary-7);
    color: var(--color-tertiary-7);
    font-size: 0.75rem;
    line-height: 1.375rem;
    line-height: var(--sidebar-item-height);
    padding: 0 0.75rem;
    margin: 0 0.625rem;
    height: 1.375rem;
    height: var(--sidebar-item-height);
    text-align: center;
}
.sidebar-item__icon {
    height: 19px;
    margin: 0;
}
.sidebar-item__icon--open {
    fill: #fff;
    left: 2.35rem;
    position: absolute;
    opacity: 0;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
}
.sidebar-item__inner__top {
    align-items: center;
    display: flex;
    height: 3.1875rem;
    height: var(--sidebar-item-container-height);
    padding-left: 1.4375rem;
    position: relative;
}
.sidebar-item__bar {
    border-right: 5px solid transparent;
    height: auto;
    margin: 0.8rem 0 0.8rem auto;
}
[secondary-sidebar="close"] .active .sidebar-item__icon--open {
    opacity: 1;
}
.sidebar-item__label {
    color: var(--color-primary-6);
    color: var(--color-primary-6);
    font-size: 0.9375rem;
}
.sidebar-item__label--top {
    color: #286D8B;
    color: var(--sidebar-top-item-color);
    font-size: 0.8125rem;
    padding-left: 1.4375rem;
    position: relative;
    top: 2px;
}
  
  /* Active states */
.active .sidebar-item__bar {
    border-color: var(--color-tertiary-6);
    border-color: var(--color-tertiary-6);
}
.active .sidebar-item__label {
    color: var(--color-tertiary-6);
    color: var(--color-tertiary-6);
}
