
  /* GLOBAL */
[data-v-0a33f7f2]:root {
    --tab-border-color: #d2d2d2;
}
.content[data-v-0a33f7f2] { height: auto; padding: 2rem;
}
.content__wrap--sidebar[data-v-0a33f7f2] {
    overflow-x: hidden;
}
.button--group svg[data-v-0a33f7f2] {
    bottom: 1px;
    position: relative;
}

  /* HEADER */
header[groups="true"][data-v-0a33f7f2] {
    border-bottom: 1px solid #a9b9bf;
}
header[groups="true"] .header-container[data-v-0a33f7f2] {
    border-bottom: 0;
}
.header-container[data-v-0a33f7f2] {
    align-items: flex-end;
    border-bottom: 1px solid var(--background-light);
    border-bottom: 1px solid var(--background-light);
    display: flex;
    justify-content: space-between;
    margin-left: auto;
}
.header-container__right[data-v-0a33f7f2] {
    align-items: center;
    display: flex;
    margin-left: auto;
    position: relative;
}
.append[data-v-0a33f7f2] {
    display: flex;
    justify-content: flex-end;
    transition: width 0.25s ease-in-out;
}
.append .button__icon[data-v-0a33f7f2] {
    margin: 0;
    overflow: hidden;
    width: 0;
    -webkit-transform-origin: 50% 6.5px;
            transform-origin: 50% 6.5px;
    transition: all 0.5s ease-in-out;
}
.append[data-v-0a33f7f2]:hover {
    width: 9.313rem;
}
.append:hover .button__icon[data-v-0a33f7f2] {
    margin: 0.625rem;
    min-width: 13px;
    width: auto;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
}
@media screen and (max-width:472px) {
.header-container__right[data-v-0a33f7f2] {
      margin-left: 0;
      margin-right: unset;
      width: 100%;
}
.tab + .tab-group--view[data-v-0a33f7f2] {
      margin-left: auto;
}
}
@media screen and (max-width:950px) {
.content[data-v-0a33f7f2] { height: auto;
}
}
@media screen and (min-width: 472px) {
.header-container__right[data-v-0a33f7f2] {
      min-width: 15.5rem;
      justify-content: flex-end;
}
.header-container__right[data-v-0a33f7f2]::after {
      background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--background-light));
      background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--background-light));
      content: "";
      height: 2.5rem;
      left: -3rem;
      pointer-events: none;
      position: absolute;
      bottom: 0;
      width: 3rem;
}
}
.el-pager li[data-v-0a33f7f2],
  .el-pagination button[data-v-0a33f7f2] {
    background: transparent !important;
}
