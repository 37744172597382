
[domain-link][data-v-222158d2] {
    fill: var(--color-primary-gray);
    fill: var(--color-primary-gray);
    margin: 0 0.3125rem;
}
[domain-link][data-v-222158d2]:hover {
    cursor: pointer;
    fill: var(--color-tertiary-blue-focus);
    fill: var(--color-tertiary-blue-focus);
}
li[data-v-222158d2] {
    display: flex;
}
li > p[data-v-222158d2] {
    margin: 0;
    max-width: 165px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.zingnetCard--column[data-v-222158d2] {
    min-height: 265px;
    min-width: 330px;
}
.zingnetCard--column[data-v-222158d2]:nth-child(3) {
    min-width: 476px;
}
