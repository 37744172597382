
.password-validation {
  margin: 0;
  padding: 0;
}
.password-validation li {
  list-style-type: none;
}
.password {
  color: #a2a2a2;
}
.password--valid {
  position: relative;
  list-style-type: none;
  color: #4caf50;
}
.password--invalid {
  color: #f44336;
  list-style-type: none;
  position: relative;
}
.layout--row {
  display: flex;
}
