
[data-v-c23fbf8c] { box-sizing:border-box;
}
#builder[data-v-c23fbf8c] { padding:0 1rem 1rem 1rem;
}
body[data-v-c23fbf8c] { margin:0;
}
@media screen and (min-width: 992px) {
#builder[data-v-c23fbf8c] { display:flex; flex-direction:column; height:100%;
}
}
