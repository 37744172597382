
[group-none="buttonContainer"] {
    display: flex;
}
[group-none] .el-form .el-button {
    margin-left: 1rem;
}
[group-none="inputRow"] .el-form-item__content {
    display: flex;
}
