
.flex[data-v-3d565b7a] {
    display: flex;
}
.flex > div[data-v-3d565b7a] {
    padding: var(--domain-modal-padding);
    padding: var(--domain-modal-padding);
    width: 50%;
}
.flex > div + div[data-v-3d565b7a] {
    border-left: var(--zingnet-border);
    border-left: var(--zingnet-border);
}
section + section[data-v-3d565b7a] {
    margin-top: 1.875rem;
}
