
.ccard__mini {
    display: flex;
    align-items: center;
}
.ccard__mini .ccard__number {
    font-size: 0.9rem;
}
.ccard__mini .ccard__brand {
    margin-right: 1rem;
}
.ccard__header {
    padding: 0 1rem;
}
.ccard__frame {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 215px;
    border: 1px solid #333;
    border-radius: 15px;
    margin: 1rem 0;
    background: linear-gradient(to top, #818284, #2c3e50);
    color: #f3f3f3;
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
}
.ccard__frame:hover {
    box-shadow: var(--box-shadow-card-hover);
    box-shadow: var(--box-shadow-card-hover);
}
.ccard__frame--blank {
    justify-content: center;
    align-items: center;
    background: none;
    cursor: pointer;
    border: 1px solid #EFEFEF;
    color: var(--color-primary-1);
    color: var(--color-primary-1);
}
.ccard__number {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    height: 40%;
    padding: 0 1rem 1rem;
    font-size: 1.2rem;
    letter-spacing: 4px;
    font-family:Courier, Times, serif;
}
.ccard__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem 1rem;
    font-family:Courier, Times, serif;
}
@media screen and (min-width: 400px) {
.ccard__frame {
      width: 335px;
}
}
