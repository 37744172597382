
[demo-view-2] .demos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: var(--row-padding) 0;
    padding: var(--row-padding) 0;
}
[demo-view-2] .demo-pagination{
    display: flex;
    width: 100%;
    padding-top: 1rem;
    border-top: 1px solid #eee;
}
[demo-view-2] .el-pagination {
    text-align: center;
    justify-content: center;
    margin-bottom: 2rem;
    flex: 1;
}
[demo-view-2][mode="list"] .demos {
    margin-top: 1.3rem;
}
[demo-view-2] .demo-controls__right {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
}
[demo-view-2] [demo-card] {
    width: 33%
}
[demo-view-2] [demo-card] .demo__action {
    display: none;
}
@media screen and (max-width:1200px) {
[demo-view-2] [demo-card] {
      width: 50%
}
}
@media screen and (max-width:1000px) {
[demo-view-2] [demo-card] {
    width: 90%
}
}
