
.flex[data-v-0276abf2] {
    display: flex;
}
.flex > div[data-v-0276abf2] {
    padding: var(--domain-modal-padding);
    padding: var(--domain-modal-padding);
    width: 50%;
}
.flex > div + div[data-v-0276abf2] {
    border-left: var(--zingnet-border);
    border-left: var(--zingnet-border);
}
.domainNote[data-v-0276abf2] {
    border: var(--zingnet-border);
    border: var(--zingnet-border);
    border-radius: 10px;
    overflow: hidden;
}
.domainNote + .domainNote[data-v-0276abf2] {
    margin-top: 1.125rem;
}
.domainNote--body[data-v-0276abf2] {
    font-size: 0.9375rem;
    padding: 1rem 1.125rem;
}
.domainNote--container[data-v-0276abf2] {
    max-height: 20rem;
    overflow: scroll;
}
.domainNote--header[data-v-0276abf2] {
    background-color: var(--zingnet-color-gray);
    background-color: var(--zingnet-color-gray);
    border-bottom: var(--zingnet-border);
    border-bottom: var(--zingnet-border);
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    font-style: italic;
    padding: 1rem 1.125rem;
}
section + section[data-v-0276abf2] {
    margin-top: 1.875rem;
}
