
section[data-v-2efe2d1b] {
    margin-top: 25px;
}
.zingBadge-section[data-v-2efe2d1b] {
    border: 3px solid white;
    border-radius: 4px;
    flex: 1 0 45%;
    padding: 25px 30px;
    position: relative;
    margin: 40px 10px 10px;
    text-align: center;
}
.zingBadge-section h2[data-v-2efe2d1b] {
    background: #e0e0e0;
    left: 0;
    margin: auto;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    text-align: center;
    top: -19px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.zingBadges[data-v-2efe2d1b] {
    display: flex;
    flex-wrap: wrap;
}
