
.social-option {
  display: flex;
  /* border: 1px solid rgb(201, 201, 201); */
  border-radius: 5px;
  align-items: center;
  margin: 1rem;
}
.social-option--icon {
  margin: 0 1rem;
}
.social-option--name {
  font-size: 1.4rem;
}
