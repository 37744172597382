
[icon="generate"][data-v-3d0de49f] {
    margin-right: 0.5rem;
    position: relative;
    top: 1px;
}
p[data-v-3d0de49f] {
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    margin: 1rem 0;
}
.section + .section[data-v-3d0de49f] {
    margin-top: 2.3125rem;
}
