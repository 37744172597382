
.plan-alerts {
    display: flex;
    flex: 1;
    flex-direction: column-reverse;
}
.plan-alerts .el-alert {
    margin-bottom: 0.5rem;
}
.plan-domains {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
}
.plan-domains__container {
    max-height: 165px;
    overflow: auto;
}
.plan-domains__list {
    align-items: center;
    background: white;
    border-radius: 5px;
    display: flex;
    font-size: 0.9rem;
    font-weight: 500;
    height: auto;
    justify-content: start;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
}
.plan-domains__count {
    background: var(--color-primary-1);
    background: var(--color-primary-1);
    border-radius: 5px 0 0 5px;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: relative;
    padding: 0.5rem;
}
.plan-domains__delete {
    padding: 0.5rem;
    border-radius: 0 5px 5px 0;
    color: var(--color-secondary-1);
    color: var(--color-secondary-1);
    cursor: pointer;
}
.plan-domains__add {
    display: flex;
}
.plan-domains__add .el-form-item {
    flex: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    margin-bottom: 2.5rem;
}
.plan-domains__add .el-button {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 1.9rem;;
    left: -56px;
    position: absolute;
    width: 55px;
}
.plan-domains__header,
  .plan-dialog__footer {
    display: flex;
}
.plan-domains__url {
    flex: 1;
    padding: 0.5rem 0 0.5rem 1rem;
}
.plan__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    padding: 1rem 0;
    width: 100%;
    word-break: break-word;
}
@media screen and (min-width: 512px) {
.plan-domains {
      width: 60%;
}
}
.plan-domains__add .el-form-item .el-input__inner {
    padding-right: 4rem;
}
