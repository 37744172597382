
h2[data-v-757c26ee] {
    margin: 1rem;
}
#sadSVG[data-v-757c26ee] {
    width: 100%;
}
main[data-v-757c26ee] > * {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
main > .show[data-v-757c26ee] {
    opacity: 1;
    pointer-events: all;
    position: relative;
}

  /* HEADER */
.content__header[data-v-757c26ee] {
    border-bottom: 0;
    color: var(--color-primary-blue);
    color: var(--color-primary-blue);
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300;
    font-size: 2.5rem;
    margin-top: 0;
}
header.content__header[data-v-757c26ee] {
    margin-top: 2.5rem;
}

  /* TAB */
.tab[data-v-757c26ee] {
    background: #fff;
    border: var(--border);
    border: var(--border);
    border-radius: 0;
    box-shadow: 0px 1.67483px 3.34965px 0.837413px rgba(73, 79, 94, 0.07);
    font-family: "Nunito Sans", sans-serif;
    font-size: 1rem;
    font-weight: normal;
    padding: 0.35rem 1rem;
    margin: 1px;
}
.tab.active[data-v-757c26ee] {
    background: var(--color-primary-gray);
    background: var(--color-primary-gray);
    color: #fff;
}
.tab[data-v-757c26ee]:not(.active) {
    background: #fff;
    border: var(--border);
    border: var(--border);
    color: var(--color-primary-blue);
    color: var(--color-primary-blue);
}
.tab[data-v-757c26ee]:not(.active):hover {
    border-color: #40a6c3;
    color: var(--color-tertiary-blue-focus);
    color: var(--color-tertiary-blue-focus);
}
.tab + .tab[data-v-757c26ee],
  .tab + .tab-group[data-v-757c26ee] {
    margin-left: 0.1rem;
}
@media only screen and (max-width: 2200px) {
.content__header[data-v-757c26ee] {
      font-size: 2rem;
}
.content--narrow-alt[data-v-757c26ee] {
      max-width: var(--max-width);
      max-width: var(--max-width);
}
}
