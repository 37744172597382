
.content--narrow { margin-bottom: 5rem;
}
.default__setting { display: flex; align-items: center;
}
.default__setting h4 { margin: 1rem 0.5rem 0.5rem;
}
.flex__container { align-items: center; display: flex; justify-content: space-between;
}
.el-radio-group { margin: 1rem 0 0.5rem;
}
