
.warning h1, .warning h2 { text-align: center;
}
.warning h1 { font-size: 2.5rem; margin: 3rem 0 0;
}
.warning h2 { color: var(--color-secondary-3); color: var(--color-secondary-3); font-size: 1.25rem; margin: 0;
}
.warning [light] h1 { color: var(--color-reset); color: var(--color-reset);
}
.warning #sadSVG { width:100%;
}
