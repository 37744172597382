
/* Element Ui Overwrites */
[pricing] .el-button {
  border-radius: 6px !important;
  font-size: 0.8125rem;
  font-weight: 600;
  transition: background-color .1s,border-color .1s,color .1s !important;
}
[pricing] .el-button.el-button--default {
  border-color: #b9bfc5 !important;
  color: #b9bfc5 !important;
}
[pricing] .el-button.el-button--primary {
  background: #04a3f5 !important;
  border-color: #04a3f5 !important;
  color: #fff !important;
}
[pricing] .el-button.el-button--primary:not(.is-disabled):hover {
  background-color: var(--color-tertiary-5) !important;
  background-color: var(--color-tertiary-5) !important;
  border-color: transparent !important;
}
[pricing] .el-button.is-disabled {
  background: #ebebeb !important;
  border-color: #ebebeb !important;
  color: #fff !important;
}
[pricing] .is-link {
  color: var(--color-tertiary-7) !important;
  color: var(--color-tertiary-7) !important;
  font-weight: 800;
  margin-top: 1rem;
  text-decoration: underline;
}
[pricing] .el-button.el-button--danger.is-plain {
  background-color: #fff !important;
  border: 1px solid #dd1659 !important;
  color: #dd1659 !important;
}
[pricing] .el-date-editor.el-input {
  width: 100% !important;
}
[pricing] .el-date-editor input,
[pricing] .el-input input {
  border-color: #dedede !important;
  color: #357083 !important;
  height: 2.5rem !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.02rem;
  padding: 0 15px !important;
}
[pricing] .el-input input::-webkit-input-placeholder {
  color: #d2d2d2 !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.02rem !important;
}
[pricing] .el-input input::-moz-placeholder {
  color: #d2d2d2 !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.02rem !important;
}
[pricing] .el-input input::-ms-input-placeholder {
  color: #d2d2d2 !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.02rem !important;
}
[pricing] .el-input input::placeholder {
  color: #d2d2d2 !important;
  font-size: 0.75rem !important;
  letter-spacing: 0.02rem !important;
}
[pricing] .el-form-item {
  margin-bottom: 0.5rem;
}
[pricing] .el-form-item__content {
  line-height: inherit;
}
[pricing] .el-form-item__error {
  position: relative !important;
}
[pricing] .el-form-item__label {
  color: #808486;
  font-size: 0.6875rem;
  line-height: 1.5rem;
  padding: 0.15rem 0 0.25rem !important;
}
[pricing="formLogin"] .el-form-item__label,
[pricing="formSignup"] .el-form-item__label {
  color: var(--color-primary-1);
  color: var(--color-primary-1);
  font-weight: 500;
}
[pricing="button-secondary"].el-button.el-button--primary {
  background: #00394b !important;
  border-color: #00394b !important;
}
[pricing="button-secondary"].el-button.el-button--primary:hover {
  background-color: #778c92 !important;
  border-color: transparent !important;
}
[pricing="button-flex"],
[pricing*="form"] > .el-form-item:last-of-type .el-form-item__content {
  display: flex;
  margin-top: 1.25rem;
}
[pricing="button-flex"] .el-button,
[pricing*="form"] .el-button {
  font-size: 0.8125rem;
  font-weight: 600;
}
[pricing="button-flex"] .el-button + .el-button,
[pricing*="form"] .el-button + .el-button {
  margin-left: auto;
}
[pricing="button-flex"] .el-button,
[pricing="formPricing"] .el-button {
  width: 45.8333%;
}
[pricing="btn--closeform"].el-button.el-button--default {
  background-color: #fff;
  border-color: #003748 !important;
  color: #003748 !important;
  left: 1rem;
  position: fixed;
  top: 1rem;
  z-index: 999;
}
[pricing="formLogin"] .el-button,
[pricing="formSignup"] .el-button {
  width: 100%;
}
@media screen and (max-width: 400px) {
[pricing="button-flex"] {
    flex-wrap: wrap;
}
[pricing="button-flex"] .el-button {
    width: 100%;
}
[pricing="button-flex"] .el-button + .el-button {
    margin-top: 1rem;
}
}
