
.settings__dialog {
    width: 80% !important;
    max-width: 60rem;
}
.settings__dialog .el-dialog__body {
    padding: 30px 3rem 20px 30px;
}
[demo-settings="metadata__add"] {
    margin-top: 1rem;
}
.demo-control__item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.settings__dialog .demo-control__item {
    max-width: 400px;
}
.settings__dialog #pane-1 .el-input-icon {
    right: 0.6rem;
    top: 0.1rem;
}
.settings__dialog #pane-1 .el-input-group__append {
    padding: 0px 40px;
}
.settings__dialog #pane-2 .el-input-group__append {
    border: none;
    padding: 0 2rem;
}
.settings__dialog #pane-0 .input-new-tag .el-select__wrapper {
    height: 24px !important;
    font-size: var(--el-tag-font-size) !important;
    font-size: var(--el-tag-font-size) !important;
}
.settings__dialog #pane-0 .el-tag,
  .settings__dialog #pane-0 .button-new-tag  {
    margin: 10px 10px 0 0;
}
.settings__dialog section header {
    display: flex;
    align-items: center;
}
.settings__dialog section header * + * {
    margin-left: 1rem;
}
.upload-dialog__file__thumbnail--image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 28px;
    margin-right: 0.5rem;
    width: 35px;
}
.upload-dialog__file__thumbnail--text {
    height: 28px;
    margin-right: 0.5rem;
    position: relative;
    right: 2px;
    width: 27px;
}
[demo-settings="metadata"] zing-grid {
    border: 0;
    margin-bottom: 1rem;
}
[demo-settings="metadata"] zing-grid zg-status {
    display: none;
}
