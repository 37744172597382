
[googleEntry].button {
    background: #fff;
    border-radius: var(--border-rounded--small);
    border-radius: var(--border-rounded--small);
    box-shadow: rgba(0, 0, 0, 0.24) 0 1px 4px 0px;
    color: #707070;
    cursor: pointer;
    display: flex;
    font-size: 0.625rem;
    justify-content: center;
    text-transform: uppercase;
    transition: background 0.15s cubic-bezier(0.6, -0.28, 0.74, 0.05);
}
[googleEntry].button:hover { background: rgba(9, 30, 66, 0.02);
}
[googleEntry="button__icon"] svg { border-bottom-left-radius: 3px; border-top-left-radius: 3px; height: 1.125rem; padding: 0 0.45rem 0 0.75rem; position: relative; top: 0.15rem; width: auto;}
[googleEntry="button__text"] { font-weight: 900; margin-left: 0; padding: 0.75rem 0.75rem 0.75rem 0; position: relative; top: 2px;
}
@media screen and (min-width:472px) {
[googleEntry="button__text"] { margin-left: 3px;
}
}
