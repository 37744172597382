.filter__option[data-v-6c844370] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter__count[data-v-6c844370] {
  align-items: center;
  background: var(--color-tertiary-5);
  background: var(--color-tertiary-5);
  border-radius: 20px;
  color: #FFF;
  display: flex;
  font-size: 0.8rem;
  height: 20px;
  justify-content: center;
  min-width: 20px;
  padding: 0 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.filter__name[data-v-6c844370] {
  max-width: 10rem;
  overflow: hidden;
  padding-right: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter_wrapper[data-v-6c844370] {
  position: relative;
  width: 100%;
}
.el-select:hover + [icon="close--circle"].active[data-v-6c844370] {
  opacity: 1;
}
[icon="close--circle"][data-v-6c844370] {
  background: #fff;
  fill: var(--color-tertiary-8);
  fill: var(--color-tertiary-8);
  height: 1.5rem;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 5px;
  width: 0.8rem;
  z-index: 1;
}