
.el-button[data-v-b46bbe5e] {
    margin-top: 0;
}
hr[data-v-b46bbe5e] {
    border: 0;
    border-top: var(--zingnet-border);
    border-top: var(--zingnet-border);
    margin: 0.9375rem 0 0.625rem;
}
[icon="run"][data-v-b46bbe5e] {
    margin-left: 0.3125rem;
}
p[data-v-b46bbe5e] {
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    margin: 0;
}
.zingnetCard--column[data-v-b46bbe5e] {
    max-width: 24%;
}
