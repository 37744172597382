
[demo-card] .demo__action {
    margin-top: 1rem;
}
[demo-card] .demo__card {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
    cursor: pointer;
    display: flex;
    flex-basis: 31%;
    flex-direction: column;
    height: 9.375rem;
    margin-top: 2.25rem;
    max-height: 9.375rem;
    max-width: 22rem;
    overflow: visible;
    padding: 1rem 1.4375rem;
    transition: box-shadow 0.25s ease-out;
}
[demo-card] [mode="grid"] .demo__card--dummy {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
}
[demo-card] .demo__header--dummy {
    height: auto;    
    position: relative;
    width: 40%;
}
[demo-card] .demo__header__left--dummy {
    min-width: 50%;
    width: 40%;
}
[demo-card] .demo__card .button svg {
    margin-right: 0.4375rem;
}

  /* Grid Mode : Hover */
[demo-card] .demo__card:hover {
    background: var(--color-tertiary-7);
    background: var(--color-tertiary-7);
    border-color: var(--color-tertiary-7);
    border-color: var(--color-tertiary-7);
}
[demo-card] .demo__card:hover .button {
    background: #fff;
    border-color: #fff;
    color: var(--color-tertiary-7);
    color: var(--color-tertiary-7);
}
[demo-card]  .demo__card:hover .button svg {
    fill: var(--color-tertiary-7);
    fill: var(--color-tertiary-7);
}
[demo-card] .demo__card:hover .demo__card--dummy {
    display: inherit;
}
[demo-card] .demo__card:hover .demo__date,
  [demo-card] .demo__card:hover .demo__description,
  [demo-card] .demo__card:hover .demo__title {
    color: #fff;
}
[demo-card] .demo__card:hover .demo__icon {
    fill: #fff;
}
[demo-card] .demo__card:hover .demo__body {
    display: flex;
}
[demo-card] [demo-viewer] .demo__card:hover .demo__image__wrapper {
    background-color: var(--color-tertiary-7);
    background-color: var(--color-tertiary-7);
}
[demo-card] [mode="grid"] .grid-hide,
  [demo-card] [mode="list"] .list-hide,
  [demo-card] .demo__card:hover .hover-hide,
  [demo-card] .demo__card-dummy:hover .hover-hide,
  [demo-card] .demo__card:not(:hover) .not-hover-hide {
    height: 0 !important;
    margin: 0;
    opacity: 0 !important;
    padding: 0;
    width: 0;
}
[demo-card] [mode="grid"] .demo__card--dummy:hover {
    padding: 1rem 6.5rem;
    max-width: 22rem;
}
[demo-card] [mode="grid"] .demo__card:hover .demo__image,
  [demo-card] [mode="grid"] .demo__card:hover .demo__image__wrapper {
    height: 0 !important;
    overflow: hidden;
    padding: 0;
    width: 0;
}
  /* List */
[demo-card] [mode="list"] .demo__card {
    display: flex;
    flex-basis: 100%;
    height: 3.125rem;
    padding: 0 0.75rem;
    margin: 0 0 0.375rem 0;
    max-width: 100%;
}

  /* List Mode : Hover */
[demo-card] [mode="list"] .demo__card:hover .demo__button {
    opacity: 1;
    pointer-events: all;
}
[demo-card] [mode="list"] .demo__card:hover .tag {
    background: var(--color-tertiary-7);
    background: var(--color-tertiary-7);
    border: 1px solid #fff;
    color: #fff;
}
[demo-card] [mode="list"] .demo__card:hover .tag-button,
  [demo-card] [mode="list"] .demo__card:hover [aria-controls="dropdown-menu"][role="button"] svg {
    color: #fff;
}
[demo-card] [mode="list"] .demos .demo__card {
    padding: 0.75rem 1.4375rem;
}

  /* Template */
[demo-card] [template] .demo__card {
    background: var(--background-light);
    background: var(--background-light);
    border: 1px solid #d8d8d8;
    -o-border-image: url('../assets/images/dashed-border.png') 1 round;
       border-image: url('../assets/images/dashed-border.png') 1 round;
}
[demo-card] [template] .demo__card:hover {
    background: var(--color-tertiary-7);
    background: var(--color-tertiary-7);
    border-color: var(--color-tertiary-7);
    border-color: var(--color-tertiary-7);
}

  /* Element Overwrites */
[demo-card] .el-dropdown:hover [aria-controls="dropdown-menu"][role="button"] svg {
    color: #fff;
}
[demo-card] [aria-controls="dropdown-menu"][role="button"] svg {
    color: var(--color-greyscale-10);
    color: var(--color-greyscale-10);
    fill: var(--color-greyscale-10);
    fill: var(--color-greyscale-10);
}
@media screen and (max-width: 1200px) {
[demo-card] .demo__card {
      flex-basis: 48%;
      max-width: none;
      max-width: initial;
}
[demo-card] .demo__card:nth-child(odd) {
      margin-left: 1rem;
}
}
@media screen and (max-width: 872px) {
[demo-card] .demo__card {
      flex-basis: 100%;
      max-width: none;
      max-width: initial;
}
[demo-card] .demo__card:nth-child(odd) {
      margin-left: 0;
}
}
