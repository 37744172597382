
div[data-v-e5492c0c] {
    align-items: center;
    border: var(--zingnet-border);
    border: var(--zingnet-border);
    border-radius: 10px;
    display: flex;
    font-size: 0.9375rem;
    font-weight: 400;
    padding: 0.75rem 1.375rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
div p[data-v-e5492c0c] {
    margin: 0;
}
.danger[data-v-e5492c0c] {
    background-color: var(--color-tertiary-danger);
    background-color: var(--color-tertiary-danger);
    border-color: var(--color-secondary-danger);
    border-color: var(--color-secondary-danger);
    color: var(--color-primary-danger);
    color: var(--color-primary-danger);
    fill: var(--color-secondary-danger);
    fill: var(--color-secondary-danger);
}
