
[logout] .button--bottom[data-v-fcaa67a2] { width: 11.25rem;
}
[logout] .button--bottom p[data-v-fcaa67a2] { color: var(--color-reset); color: var(--color-reset); font-size: 0.8125rem; margin: auto;
}
[logout] .entry__actions > div[data-v-fcaa67a2] { position: relative;
}
[logout] h1[data-v-fcaa67a2] { color: var(--header-color); color: var(--header-color); font-size: 1.375rem; margin: 0 auto 1.5rem; max-width: var(--container-width); max-width: var(--container-width);text-align: center;
}
[logout] p[data-v-fcaa67a2] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem; margin: auto;
}
[logout="center"][data-v-fcaa67a2] { align-self: center;
}
[logout="center"].center--content-inline[data-v-fcaa67a2] { display: flex; margin: auto;
}
[logout="container"][data-v-fcaa67a2] { text-align:center;
}
[logout="container__body"][data-v-fcaa67a2] { flex: 1; padding: 0.5rem 0 1.5rem;
}
[logout="link"][data-v-fcaa67a2] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); font-weight: 900; text-decoration: underline;
}
[logout="link--logout" ] a[data-v-fcaa67a2] { text-decoration: none;
}
[logout="link--logout" ] p[data-v-fcaa67a2] { color: var(--color-reset); color: var(--color-reset); font-size: 0.9rem; font-weight: 500; letter-spacing: 1px; margin-right: 0.5rem; text-transform: uppercase;
}
[logout] [logout="margin--bottom"][data-v-fcaa67a2] { margin-bottom: 0.625rem;
}
[logout] [logout="link--return" ][data-v-fcaa67a2] { margin: 2.813rem auto auto auto;
}

