@import url('https://fonts.googleapis.com/css?family=Roboto:500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700&display=swap');

* {
  box-sizing: border-box;
} 

:root {
  /* GLOBALS
  --------------------------- */
  /* Color */
  --color-primary-1: #003749;
  --color-primary-2: #2e5c6a;
  --color-primary-3: #2d5b6a;
  --color-primary-4: #456d7a;
  --color-primary-5: #778c92;
  --color-primary-6: #112F3B;
  --color-primary-7: #063747;
  --color-primary-8: #507281;

  --color-secondary-1: #dc1257;
  --color-secondary-2: #df4277;
  --color-secondary-3: #e25786;
  --color-secondary-4: #e66b94;
  --color-secondary-5: #eb7fa4;

  --color-tertiary-1: #04a3f5;
  --color-tertiary-2: #19a6cf;
  --color-tertiary-3: #31afd4;
  --color-tertiary-4: #48b8d9;
  --color-tertiary-5: #5dc1dc;
  --color-tertiary-6: #1EBAED;
  --color-tertiary-7: #2BBEEE;
  --color-tertiary-8: #7BBAD7;
  --random: #E2F4FA;

  --color-quaternary-1: #218569;
  --color-quaternary-2: #369075;
  --color-quaternary-3: #499b83;
  --color-quaternary-4: #5ca591;
  --color-quaternary-5: #70b29e;

  --color-greyscale-1: #263137;
  --color-greyscale-2: #556f7a;
  --color-greyscale-3: #a9b8c1;
  --color-greyscale-4: #e1e8ee;
  --color-greyscale-5: #f6f8fa;
  --color-greyscale-6: #444;
  --color-greyscale-7: #6f6f6f;
  --color-greyscale-8: #8a8a8a;
  --color-greyscale-9: #f1f1f1;
  --color-greyscale-10: #ABB6C2;
  --color-greyscale-11: #D4D6DF;
  --color-greyscale-12: #8EB6C7;

  --color-alert: red;
  --color-alert-light: #f8f2f4;
  --color-disabled: #7a7a7a;
  --color-fail: #e94a7c;
  --color-reset: #fff;
  --color-success: #00e7c0;

  --color-primary-blue: #13668F;
  --color-secondary-blue: #00BAF0;
  --color-secondary-blue-active: #00779A;
  --color-secondary-blue-visited: #9DCBD8;
  --color-tertiary-blue: #074361;
  --color-tertiary-blue-focus: #0088AF;
  --color-tertiary-blue-visited: #7F9099;

  --color-primary-gray: #5D7D9A;
  --color-tertiary-gray: #E8E8E8;
  --color-primary-gray-visited: #A5B3BF;
  --color-secondary-gray: #BDC0C3;

  --color-primary-danger: #902541;
  --color-secondary-danger: #CB3159;
  --color-tertiary-danger: #FDF2F5;

  --color-hover-light: #e3f5ff;

  /* Background */
  --background-dark: #1C4E63;
  --background-disabled: #eee;
  --background-mid: #e0e0e0;
  --background-light: #EFF3F6;
  /* Borders */
  --border-disabled: #e1e8ee;
  --border-disabled: var(--color-greyscale-4);
  --border: 0.84px solid #E5E9F0;
  --border-light: #e1e8ee;
  --border-light: var(--color-greyscale-4);
  --border-radius: 0.3125rem;
  --border-radius-medium: 0.625rem;
  --border-radius-large: 1.6667rem;
  /* Box Shadow */
  --box-shadow-card: 1px 2px 8px rgba(153, 153, 153, 0.38039);
  --box-shadow-card-hover: 1px 2px 10px rgba(95, 95, 95, 0.4);
  --box-shadow-section: 0 2px 13px rgba(0, 0, 0, .1);
  /* Button */
  --btn-height: 1.5625rem;
  --btn-icon-margin: 13px;
  /* Easing */
  --easing-easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --easing-siteBezier: cubic-bezier(.05,.69,.14,1);
  /* Fonts */
  --font-color: #003749;
  --font-color: var(--color-primary-1);
  --font-family: 'Nunito Sans', 'Lucida Sans Unicode', sans-serif;
  --font-line-height: 1.5;
  --font-size-large: 1.25rem;
  --font-size-normal: 1rem;
  --font-size-small: .875rem;
  --font-weight: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-900: 900;
  /* Form */
  --form-element-background-color: #fff;
  --form-element-background-color: var(--color-reset);
  --form-element-background-color_focus: #fbfbfb;
  --form-element-border-color: #e2e2e2;
  --form-element-box-shadow: 0 1px 2px #e6e5e5;
  --form-element-color: #003749;
  --form-element-color: var(--color-primary-1);
  --form-element-font-weight: 600;
  --form-element-height: 54px;
  --form-element-icon-width: 20px;
  --form-element-margin: 1rem;
  --form-element-padding: 1rem;
  --form-element-padding-left-with-icon: calc(1rem + 20px + 12px);
  --form-element-padding-left-with-icon: calc(var(--form-element-padding) + var(--form-element-icon-width) + 12px);
  --form-element-radius: 8px;
  --form-element-placeholder-color: #ced9dc;
  --form-element-placeholder-color_focus: #9cadb3;
  --form-element-placeholder-color_disabled: #b5c5ca;
  --form-element-placeholder-font-weight: 600;
  /* Header */
  --header-height: 50px;
  --editor-tools-height: 40px;
  /* Headline */
  --headline-font-weight: 600;
  --headline-line-height: 1.3;
  --headline-margin: 1.3rem;
  --headline-margin: var(--margin);
  /* Body/Footer */
  --footer-height: 70px;
  --max-width: 1200px;
  /* SVG Icons */
  --icon-codepen: #fff;
  --icon-codepen: var(--color-reset);
  --icon-github: #fff;
  --icon-github: var(--color-reset);
  --icon-stackoverflow: #fff;
  --icon-stackoverflow: var(--color-reset);
  --icon-twitter: #fff;
  --icon-twitter: var(--color-reset);
  /* Inner */
  --inner-padding: 2rem;
  --row-padding: 15px;
  /* Links */
  --link-color: #04a3f5;
  --link-color: var(--color-tertiary-1);
  --link-hover: #485871;
  /* Margin */
  --margin: 1.3rem;
  /* Row / Columns */
  --row-justify-margin-left: 10px;
  --row-item-margin-bottom: 15px;
  --col-spacing: 40px;
  /* Sections */
  --section-bg-primary: #fff;
  --section-bg-primary: var(--color-reset);
  --section-bg-secondary: #003749;
  --section-bg-secondary: var(--color-primary-1);
  --section-bg-tertiary: #f7f7f7;
  --section-bg-quaternary: #70b29e;
  --section-bg-quaternary: var(--color-quaternary-5);
  --section-bg-greyscale: #f6f8fa;
  --section-bg-greyscale: var(--color-greyscale-5);
  --section-margin: 2rem;
  /* Tab */
  --tab-border-color: #d2d2d2;
  /* Text */
  --text-description-font-size: 1.111rem;
  /* Theme? */
  --theme-color: #00baf0;
  /* Viewport */
  --viewport-padding: 25px;
  --viewport-max-width: 1600px;

}

zing-grid {
  /* Zing-grid defaults */
  --zg-caption-background: #003749;
  --zg-caption-background: var(--color-primary-1);
  --zg-caption-color: #fff;
  --zg-caption-icon-color: #fff;
  --zg-option-list-item-background_hover: #e1e8ee;
  --zg-option-list-item-background_hover: var(--color-greyscale-4);
  --zg-head-cell-font-weight: bold;
  --zg-head-cell-color: #003749;
  --zg-head-cell-color: var(--color-primary-1);

  --zg-cell-background_sorted: #f6f8fa;

  --zg-cell-background_sorted: var(--color-greyscale-5);
  --zg-head-cell-background_sorted_hover: #e1e8ee;
  --zg-head-cell-background_sorted_hover: var(--color-greyscale-4);
  --zg-head-cell-background_sorted: #e1e8ee;
  --zg-head-cell-background_sorted: var(--color-greyscale-4);
  --zg-row-body-background_hover: #e1e8ee;
  --zg-row-body-background_hover: var(--color-greyscale-4);

  /* card mode */
  --zg-cell-card-label-font-size: .85rem;
  --zg-cell-card-label-font-weight: bold;
  --zg-cell-card-label-color: #003749;
  --zg-cell-card-label-color: var(--color-primary-1);
}

/* set the loading height and push pager controls down by setting the body height */
zing-grid[loading],
zing-grid[loading] zg-body {
  height: 400px;
}

zg-load-mask {
  /* set the color to match the gif */
  background: #1C4E63;
  background: var(--background-dark);
  opacity: .96;
  /* custom gif for loading */
  background-image: url('https://storage.googleapis.com/zg-demos.appspot.com/circle_loader.svg');
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
}

zg-layout {
  --zg-layout-icon-color: #003749;
  --zg-layout-icon-color: var(--color-primary-1);
  --zg-button-background: transparent;
  --zg-button-background_hover: #e1e8ee;
  --zg-button-background_hover: var(--color-greyscale-4);
  --zg-button-background_active: #e1e8ee;
  --zg-button-background_active: var(--color-greyscale-4);
}

/* set all footer icons to be navy */
zg-foot {
  --zg-icon-color: #003749;
  --zg-icon-color: var(--color-primary-1);
}

html, body {
  margin: 0;
  height: 100%;
  padding: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow: hidden;
}

html {
  background: #fff;
  background: var(--color-reset);
  color: #00394b;
  color: #003749;
  color: var(--font-color, #00394b);
  font-size: 1rem;
  font-size: var(--font-size-normal);
  font-family: 'Nunito Sans', 'Lucida Sans Unicode', sans-serif;
  font-family: var(--font-family);
  font-weight: 400;
  font-weight: var(--font-weight);
  line-height: 1.5;
  line-height: var(--font-line-height);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  color: #04a3f5;
  color: var(--link-color);
}

[authing] {
  display:none;
}
#app-shell {
  height: 100%;
}
.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.app-content {
  display: flex;
  background: #EFF3F6;
  background: var(--background-light);
  overflow-y: auto;
  height: calc(100% - 50px);
  height: calc(100% - var(--header-height));
}

.background--grid {
  background-image: url('../images/bg_grid.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* APP STYLING */
.settings_content {
  padding: 1rem;
}
.flex {
  display: flex;
  height: 100%;
  width: 100%;
}
.form__collection {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form__collection--reverse {
  flex-wrap: wrap-reverse;
}

.form__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0.25rem 0;
}

.form__col {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1rem;
}

.form__item {
  flex: 1;
  min-width: 200px;
  margin: 0.25rem 1rem 0.25rem 0;
}

.form__label {
  display: block;
  padding-bottom: 0.2rem;
}

.form__button {
  max-width: 250px;
}

.content__header,
.content__header h2,
.content__header h4 {
  color: #6A848F;
  font-size: 1.5625rem;
  font-weight: lighter;
  letter-spacing: 0.05rem;
  margin: 1rem 0 0.5rem;
}

.content__header {
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
  border-bottom: 1px solid var(--tab-border-color);
  display: flex;
  justify-content: space-between;
  margin: 2.8125rem 0 0.9375rem 0;
}

.content__header--justify-content__start {
  justify-content: start;
}

.content__header h4 {
  font-size: 1rem;
}

.content__header--underline {
  border-bottom: 1px solid #d2d2d2;
  border-bottom: 1px solid var(--tab-border-color);
}

h2.content__header--no-underline {
  align-self: flex-end;
  border-bottom: 0;
  margin: 0;
}

.content__header--expanded {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.content__header-more a {
  text-decoration: none;
}

.content--sidebar {
  display: flex;
  height: 100%;
  z-index: 100;
}

.content--narrow-alt {
  margin-bottom: 5rem;
  max-width: 1600px;
}

.content--wide {
  width: 100%;
}

.delete-field {
  margin-top: 2rem;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo.centered {
  margin-top: 35px !important;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height:40px !important;
}
.auth0-lock.auth0-lock .auth0-lock-badge-bottom {
  display: none;
}

textarea, select, input, button { outline: none; }

.el-button--primary {
  color: #FFF;
  background-color: #1EBAED;
  border-color: #1EBAED;
}

.el-dialog__wrapper {
  display: flex;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.el-tag {
  background-color: #e9f8fd;
  border-color: #d2f1fb;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #1EBAED;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}

.BeaconContainer, .BeaconFabButtonFrame {
  margin-bottom: 3.5rem;
}

[button-align="right"] .el-form-item__content {
  display: flex;
  justify-content: flex-end;
}

.note.red {
  background-color: #fce4ec;
  border-color: #ffc1d6;
}

.note {
  position: relative;
  margin: .75rem auto 20px;
  padding: 10px;
  max-width: 100%;
  font-size: .9rem;
  line-height: 1.7;
  background-color: #EFF3F6;
  background-color: var(--background-light);
  border: 1px solid #e1e8ee;
  border: 1px solid var(--border-light);
  border-radius: 4px;
}

/* Element Nav Overwrites: is-left */
.content--narrow > .el-tabs--left .el-tabs__active-bar.is-left {
  display: none;
}

.content--narrow > .el-tabs--left .el-tabs__header.is-left {
  border-right: 2px solid #e2e2e2;
  height: -webkit-fill-available !important;
  margin-right: 3rem !important;
}

.content--narrow > .el-tabs--left .el-tabs__content {
  padding-top: 2rem;
}

.content--narrow > .el-tabs--left .el-tabs__item.is-left {
  width: 12rem;
  text-align: left !important;
}

.content--narrow > .el-tabs--left .el-tabs__item.is-left[id*="lvl1"] {
  color: #1C4E63;
  color: var(--background-dark);
  font-size: 1.15rem;
  font-weight: bold;
  padding: 0 0 20px !important;
}

.content--narrow > .el-tabs--left .el-tabs__item.is-left[id*="lvl1"]:not(.is-disabled):hover {
  color: #556f7a;
  color: var(--color-greyscale-2);
}

.content--narrow > .el-tabs--left .el-tabs__item.is-left[id*="lvl1"].is-active {
  color: #1EBAED;
  color: var(--color-tertiary-6);
}

.content--narrow > .el-tabs--left .el-tabs__nav-wrap::after {
  background-color: transparent !important;
}

/* Buttons */
.is-link {
  color: #1EBAED !important;
  padding: 2px 0 3px !important;
}
.button {
  align-items: center;
  background: #ABB6C2;
  background: var(--color-greyscale-10);
  border: 0;
  border-radius: 0.3125rem;
  border-radius: var(--border-radius);
  color: #fff;
  cursor: pointer;
  display: flex;
  line-height: 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  min-height: 1.5625rem;
  min-height: var(--btn-height);
  padding: 0.3125rem 0.625rem;
  text-decoration: none;
}
.button + .button-group {
  margin-left: 1.25rem;
}
.button + .button {
  margin-left: 0.9375rem;
}
.button--group {
  background: transparent;
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 100%;
}
.button--group + .button--group {
  border-left: 1px solid #D4D6DF;
  border-left: 1px solid var(--color-greyscale-11);
}
.button--group svg {
  color: #8EB6C7;
  color: var(--color-greyscale-12);
}
.button--group.active svg {
  color: #2BBEEE;
  color: var(--color-tertiary-7);
}
.button--primary {
  border: #2BBEEE;
  border: var(--color-tertiary-7);
  background: #2BBEEE;
  background: var(--color-tertiary-7);
}
.button-group {
  background: transparent;
  border: 1px solid #D4D6DF;
  border: 1px solid var(--color-greyscale-11);
  border-radius: 0.3125rem;
  border-radius: var(--border-radius);
  display: flex;
  height: 1.5625rem;
  height: var(--btn-height);
}
.button-group .button + .button {
  margin: 0;
}
.button__icon {
  margin-right: 0.625rem;
}
.button__icon--top {
  position: relative;
  top: 1px;
}
/* Tags */
.tag {
  background: #D6F2FF;
  border-radius: 20px;
  color: #2BBEEE;
  color: var(--color-tertiary-7);
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 700;
  height: 1.375rem;
  line-height: 1.375rem;
  padding: 0 0.5rem;
}
.tag + .tag {
  margin-left: 0.875rem;
}

/* TAB */
.tab {
  align-items: center;
  border-top-left-radius: 0.3125rem;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: 0.3125rem;
  border-top-right-radius: var(--border-radius);
  border-right: 0;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 2.5rem;
  margin: 0;
  padding: 0.35rem 1rem 0.15rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}
.tab--small {
  font-size: 0.75rem;
  font-weight: 500;
  height: 1.875rem;
  padding: 0.3rem 1rem 0.15rem;
}
.tab.active {
  background: #1EBAED;
  background: var(--color-tertiary-6);
}
.tab:not(.active):hover {
  cursor: pointer;
  background: #2d5b6a;
  background: var(--color-primary-3);
}
.tab:last-child {
  border-right: 1px solid #d2d2d2;
  border-right: 1px solid var(--tab-border-color);
}
.tab:not(.active) {
  background: #063747;
  background: var(--color-primary-7);
}
.tab + .tab,
.tab + .tab-group {
  margin-left: 0.4375rem;
}
.tab-group {
  display: flex;
}
.tab-group .tab {
  border-bottom: 0;
  border-top-right-radius: 0;
}
.tab-group .tab:last-of-type {
  border-top-right-radius: 0.3125rem;
  border-top-right-radius: var(--border-radius);
}
.tab-group .tab + .tab {
  border-top-left-radius: 0;
  margin-left: 0;
}
.tab-scroller {
  display: flex;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* IE 10+ */
  scrollbar-width: none;  /* Firefox */
}
.tab-scroller::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* ELEMENT IO OVERRIDES */

/* Dialog */
.el-dialog {
  --el-dialog-padding-primary: 0 !important;
}
.el-dialog__header { padding:2rem 3rem !important; }
/* Switch */
.el-switch { height:0.75rem; }
/* Tabs */
.el-tabs__nav-next,
.el-tabs__nav-prev { -webkit-transform:translateY(6px); transform:translateY(6px); }
/* Inputs */
.el-input input {
  border-color: #D4D6DF;
  border-color: var(--color-greyscale-11);
  border-radius: 0.3125rem;
  border-radius: var(--border-radius);
  color: #858585 !important;
  font-size: 0.75rem !important;
  height: 1.875rem;
  line-height: 1.875rem;
  text-overflow: ellipsis !important;
}
.el-input-group__prepend {
  border-width: 0 !important;
}
.el-textarea__inner {
  color: #858585 !important;
  font-size: 0.75rem !important;
}
.el-select-dropdown {
  top: -2px !important;
  margin: 0px !important;
}
.el-select__prefix, .el-select__suffix {
  opacity: 0 !important;
}
.el-select .el-input .el-select__caret {
  color: transparent !important;
  line-height: 1.875rem !important;
}
.el-select .el-input__inner:focus {
  border-color: #2BBEEE !important;
  border-color: var(--color-tertiary-7) !important;
}
.el-select-dropdown__item.selected {
  color: #2BBEEE !important;
  color: var(--color-tertiary-7) !important;
}
.el-select__input {
  margin: 0 !important;
}
.el-popper[data-popper-placement^=bottom]>.el-popper__arrow {
  left: 2rem !important;
}
.el-input-icon {
  fill: #7BBAD7;
  fill: var(--color-tertiary-8);
  pointer-events: none;
  position: absolute;
  right: 0.9rem;
  top: 0.2rem;
}
/* Transitions */
.trigger--boxed {
  border-radius: 4px;
  display: inline-flex;
}
.trigger--relative {
  position: relative;
  z-index: 50;
}
.spin,
.spinZoom,
.zoom {
  -webkit-transform: 0;
          transform: 0;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.trigger:not(.active):hover .spin {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.trigger:not(.active):hover .spinZoom {
  -webkit-transform: rotate(135deg) scale(1.25);
          transform: rotate(135deg) scale(1.25);
}
.trigger:not(.active):hover .zoom {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}
.trigger--relative:after {
  content: "";
  bottom: 0;
  background: #2d5b6a;
  background: var(--color-primary-3);
  height: 100%;
  left: 0;
  position: absolute;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  transition: -webkit-transform 0.22s ease-in-out;
  transition: transform 0.22s ease-in-out;
  transition: transform 0.22s ease-in-out, -webkit-transform 0.22s ease-in-out;
  width: 100%;
  z-index: -1;
}
.trigger:not(.router-link-exact-active):hover::after {
-webkit-transform: scaleY(1);
        transform: scaleY(1);
}


/* Mobile Overwrites */
@media screen and (max-width:472px) {
  .mobile--hide {
    display: none !important;
  }
}

@media screen and (max-width: 800px) {
  .el-dialog {
    width: 90% !important;
  }
}

@media screen and (min-width:473px) and (max-width:800px) {
  .tablet--hide {
    display: none !important;
  }
}

@media screen and (min-width:800px) {
  .desktop--hide {
    display: none !important;
  }
}

@media screen and (min-width:800px) and (max-width:1200px) {
  .desktop-sm--hide {
    display: none !important;
  }
}

@media screen and (min-width:1200px) {
  .desktop-lg--hide {
    display: none !important;
  }
}

@media screen and (min-width:1650px) {
  .content--narrow > .el-tabs--left .el-tabs__header.is-left {
    left: 3rem;
    position: fixed;
  }
}