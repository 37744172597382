
[secondary-nav] .nav__sign-in__link svg {
    fill: var(--color-tertiary-6);
    fill: var(--color-tertiary-6);
    margin-right: 0.25rem;
    position: relative;
    top: 3px;
    width: 0.8rem;
}

  /* MOBILE STYLING  */
[secondary-nav] .nav {
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
}
[secondary-nav] .nav__content {
    display: flex;
    margin: 0;
    padding: 0 20px;
    width: 100%;
}
[secondary-nav] .nav__content--main {
    justify-content: space-between;
}
[secondary-nav] .nav__content.nav__content--wide {
    padding: 0px;
}
[secondary-nav] .nav__logo--secondary {
    margin-left: 0;
}
[secondary-nav] .nav__logo--secondary [icon] {
    display: flex;
}
[secondary-nav] .nav__sign-in {
    align-items: center;
    color: var(--color-greyscale-8);
    color: var(--color-greyscale-8);
    display: flex;
    font-size: 0.85rem;
    padding: 0;
    text-decoration: none;
}
[secondary-nav] .nav__sign-in svg {
    margin-right: 0.25rem;
}
[secondary-nav] .nav__sign-in__link {
    color: var(--color-primary-1);
    color: var(--color-primary-1);
    cursor: pointer;
    font-weight: 600;
    margin: 0 0 0 0.5rem;
}
[secondary-nav] .nav__sign-in__link:hover {
    color: var(--color-tertiary-1);
    color: var(--color-tertiary-1);
}
[secondary-nav] .nav__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 875px; 
    margin: 0 auto;
    padding: 0.75rem;
    background: #fff;
    color: var(--color-greyscale-8);
    color: var(--color-greyscale-8);
}
[secondary-nav] .text--underline { text-decoration: underline;
}
[secondary-nav] .nav__wrap--main .el-popover.el-popper { padding: 0;
}
[secondary-nav] .nav__wrap--main .el-popover.el-popper a:first-of-type { border-bottom: 1px solid rgba(128, 128, 128, 0.14902);
}
[secondary-nav] .nav__wrap--main .el-popover.el-popper a { align-items: center; display: flex; height: 65px; justify-content: center;
}
[secondary-nav] .nav__wrap--main .el-popover.el-popper a:hover { background: #f8f8f8;
}
[secondary-nav] .nav__wrap--main .el-popover.el-popper div:first-of-type { margin: 0.5rem 0;
} 
