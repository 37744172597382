
.demo-control {
    display: flex;
    justify-content: flex-end;
}
.demo-control__item {
    align-items: center;
    display: flex;
    line-height: var(--input-height);
    line-height: var(--input-height);
    margin-bottom: 1rem;
    position: relative;
    width: 100%;
}
.demo-control__item .el-input__inner {
    line-height: var(--input-height);
    line-height: var(--input-height);
}
.demo-control__item--search {
    width: 100%;
}
.demo-controls {
    background: var(--color-primary-7);
    background: var(--color-primary-7);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 1rem 0.9375rem 0.875rem;
}
[class^="demo-controls__lvl"] {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 250px;
}
[class^="demo-controls__lvl"] + [class^="demo-controls__lvl"] {
    padding-top: 0.75rem;
}
.demo-controls__left {
    display: flex;
    flex-direction: column;
}
.demo-controls__right {
    width: 100%;
}
.demo-controls__right [icon="search"] {
    top: 0.25rem;
}
.demo-controls__row {
    display: flex;
    justify-content: space-between;
}
.demo-controls__row + .demo-controls__row {
    padding-top: var(--row-padding);
    padding-top: var(--row-padding);
}
[demo-viewer] .button {
    background: transparent;
    color: var(--color-primary-1);
    color: var(--color-primary-1);
}
[chartTypeThumbnail],
  [charttypethumbnail] {
    fill: transparent;
    -webkit-filter: grayscale(100%) sepia(10%) hue-rotate(149deg) saturate(160%) drop-shadow(2px 4px 4px rgba(87, 87, 87, 0.32157));
            filter: grayscale(100%) sepia(10%) hue-rotate(149deg) saturate(160%) drop-shadow(2px 4px 4px rgba(87, 87, 87, 0.32157));
    height: 8rem;
    margin: 1rem 0 0 0;
    opacity: 0.85;
    width: 100%;
}

  /* Element Overwrites */
[demo-viewer] {
    --color-text: #858585;
    --zg-pager-padding: 1.25rem 0;
    --zg-row-card-grid-gap: 2.3125rem;
    --zg-select-arrow-color: var(--color-text);
    --zg-select-arrow-color: var(--color-text);
}
[demo-viewer] .el-dropdown-link {
    height: 1rem;
}
[demo-viewer] .el-dropdown.active .el-dropdown-menu {
    opacity: 1 !important;
    pointer-events: all;
}
[demo-viewer] .el-dropdown-menu {
    border: 1px solid #fff;
    left: -5.5rem;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 2.5rem;
}
[demo-viewer] .el-dropdown-menu svg {
    fill: #6A848F;
    margin-right: 0.25rem;
}
[mode="list"] [demo-viewer] .el-dropdown-menu::after {
    top: -2rem;
    content: "";
    height: 2.1rem;
    left: 0;
    position: relative;
    width: 110px;
}
[mode="list"] [demo-viewer] .el-dropdown-menu::after {
    bottom: 3rem;
}
[demo-viewer] .el-popover__reference .el-dropdown-menu {
    top: 1rem;
}
[demo-viewer] .el-dropdown-menu__item {
    border-bottom: 1px solid #EBEEF5;
    color: var(--color-primary-7);
    color: var(--color-primary-7);
    cursor: pointer !important;
    font-size: 0.7rem;
    line-height: 30px;
    padding: 0.15rem 1.25rem;
}
[demo-viewer] .el-dropdown-menu__item:first-of-type:hover ~ .popper__arrow::after {
    border-bottom-color: transparent !important;
}
[demo-viewer] .el-dropdown-menu__item:last-of-type {
    border-bottom: 0;
}
[demo-viewer] .el-dropdown-menu__item:hover {
    background: var(--color-primary-8) !important;
    background: var(--color-primary-8) !important;
    border-color: var(--color-primary-8);
    border-color: var(--color-primary-8);
    color: #fff !important;
}
[demo-viewer] .el-dropdown-menu__item:hover svg {
    fill: #fff;
    cursor: pointer;
}
[demo-viewer] .el-dropdown-menu__item:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
[demo-viewer] .el-dropdown-menu__item:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.demo-controls .el-select {
    width: 100%;
}
[demo-viewer] .popper__arrow {
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
    border-bottom: 5px solid transparent !important;
}
[demo-viewer] .el-dropdown-menu__item:first-of-type:hover ~ .popper__arrow {
    border-bottom: transparent !important;
}
[mode="list"] [demo-viewer] .el-dropdown {
    top: 0;
}
[mode="list"] [demo-viewer] .popper__arrow {
    left: 86px !important;
}

  /* ZingGrid Overwrites */

  /* load mask styling */
zing-grid[loading] zg-body { padding:0;
}
zing-grid[loading] zg-load-mask { 
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    height: 600px;
    z-index:999;
    display:flex; 
    justify-content:center; 
    align-items:center; 
    background: var(--background-light); 
    background: var(--background-light);
}
.loading--image { position:relative; display: inline-block;
}
.loading--image img {
    height: 50px;
    width: 50px; 
    margin-top: -50px;
    border-radius: 50%;
    -webkit-animation: rock 1.5s cubic-bezier(.71,-.01,.23,.98) infinite;
    animation: rock 1.5s cubic-bezier(.71,-.01,.23,.98) infinite;
}
  /* end load mask styling */
[demo-viewer] {
    font-family: 'Nunito Sans', sans-serif;
    margin-top: 0.5rem;
}
[demo-viewer],
  [demo-viewer] zg-pager {
    background: transparent;
    border: 0;
}
[demo-viewer] zg-body {
    background: transparent;
    margin-top: 0.8125rem;
    padding: 0;
}
[demo-viewer] zg-body zg-row {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding: 0;
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
    transition: box-shadow 0.25s ease-out;
}
[demo-viewer] zg-body zg-row:hover {
    box-shadow: var(--box-shadow-card-hover);
    box-shadow: var(--box-shadow-card-hover);
}
[demo-viewer] zg-cell {
    height: 250px;
    max-height: 250px;
    padding: 0;
}
[demo-viewer] zg-cell > div {
    height: 100%;
    padding: 0;
    width: 100%;
}
[demo-viewer] zg-pager input,
  [demo-viewer] zg-select,
  [demo-viewer] .zg-select-selected {
    border-color: var(--color-greyscale-11);
    border-color: var(--color-greyscale-11);
    color: var(--color-text);
    color: var(--color-text);
    font-size: 0.75rem;
    height: 1.875rem;
    line-height: 0.75rem;
}
[demo-viewer] zg-pager zg-text {
    color: var(--color-primary-2);
    color: var(--color-primary-2);
}
[demo-viewer] zg-pager zg-button svg {
    fill: var(--color-tertiary-7);
    fill: var(--color-tertiary-7);
}
[demo-viewer] zg-pager zg-button[action="reload"],
  /* [demo-viewer] zg-pager zg-select, */
  [demo-viewer] zg-pager zg-text[slot="right"] {
    opacity: 0;
    pointer-events: none;
}
[demo-viewer] .zg-select-trigger {
    background: #fff;
}
[demo-viewer] zg-cell > label,
  [demo-viewer] zg-head,
  [demo-viewer] zg-status {
    display: none;
}

  /* ZingGrid .el-dropown menu */
[demo-viewer] [js-trigger],
  [demo-viewer] .el-dropdown,
  [demo-viewer] .el-dropdown-menu__item {
    cursor: pointer;
}
[demo-viewer] .el-dropdown {
    padding: 0.5rem 0.25rem;
    left: 0.75rem;
    border-radius: 4px;
    top: 0.25rem;
}
[demo-viewer] .el-dropdown .demo__icon {
    max-height: 100%;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
[demo-viewer] .el-dropdown zg-button {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.custom-load {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(245, 247, 250);
    pointer-events: none;
    opacity: 0;
}
.zing-grid-wrapper {
    position: relative;
}
.zing-grid-wrapper zing-grid[loading] { height:550px;
}
  /* List: ZingGrid Overwrites */
[mode="list"] [demo-viewer] {
    margin-top: 1.3rem;
}
[mode="list"] [demo-viewer] zg-body zg-row {
    flex-basis: 100%;
    padding: 0;
    margin: 0 0 0.375rem 0;
}
[mode="list"] [demo-viewer] zg-cell {
    height: 5.75rem;
}

  /* Grid Mode */
.button {
    line-height: 0.725rem;
    padding: 0 0.625rem;
    transition: background 0.25s ease-in-out;
}
.button svg { fill: var(--color-primary-7); fill: var(--color-primary-7);
}
[demo-viewer] zg-row:hover .button:hover {
    background: #fafdff;
}
.demo__body {
    position: relative;
}
.demo__button {
    height: var(--btn-height);
    height: var(--btn-height);
    margin: 2rem auto;
}
.demo__button--dummy {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.demo__button--dummy--list {
    margin: 0;
}
.demo__card {
    padding: 1rem 1.4375rem;
    width: 100%;
}
.demo__card,
  .demo__body,
  .demo__image__container {
    height: 100%;
    max-height: calc(100%);
}
.demo__date,
  .demo__description {
    color: #AEADAD;
    font-size: 0.6875rem;
    font-weight: normal;
    margin: 0.2rem 0 0;
}
.demo__card--dummy {
    border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.demo__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.02rem;
    min-height: 2.563rem;
}
.demo__header--dummy {
    flex: 1;
}
.demo__header__right.demo__header--dummy {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
}
.demo__header__left {
    align-self: flex-start;
    bottom: 9px;
    position: relative;
}
.demo__header__left--dummy {
    bottom: 0;
    flex: 1;
    font-size: 0.875rem;
    margin: auto 0 auto 31px;
}
.demo__icon--open {
    margin-right: 0.5rem;
}
.demo__image {
    background-color: rgba(255, 255, 255, 0.32157);
    background-size: contain;
    border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 10px rgba(87, 87, 87, 0.32157);
    margin-top: 1rem;
    max-height: 9.5rem;
    position: relative;
    width: 100%;
}
[template="true"] .demo__image {
    height: 8rem;
}
.demo__image--dummy {
    border-radius: var(--border-radius);
    border-radius: var(--border-radius);
    float: right;
    height: 45%;
}
.demo__image__wrapper[style="display: none;"] + .demo__image__default {
    height: 8rem;
}
.demo__image__default {
    background-color: #fff;
    color: var(--color-primary-2);
    color: var(--color-primary-2);
    font-size: 1.15rem;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
}
.demo__image__default--template {
    background-color: transparent;
}
.demo__image__wrapper:not(.error-default) ~ .demo__image__default,
  .demo__image__wrapper:not(.error-default) ~ .demo__image__default:after {
    display: none;
}
.demo__image__default::after {
    content: "";
    background-color: rgba(255, 255, 255, 0.32157);
    background-image: url('../assets/images/demoTemplates/default.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.demo__image__default__text {
    left: 0;
    position: absolute;
    top: 2.5rem;
    width: 100%;
    z-index: 2;
}
.demo__image__wrapper {
    background-color: #fff;
    height:100%;
}
.demo__text--dummy {
    color: var(--color-tertiary-7);
    color: var(--color-tertiary-7);
}
.demo__title {
    align-items: center;
    color: var(--color-primary-7);
    color: var(--color-primary-7);
    display: flex;
    font-size: 0.9375rem;
    font-weight: 600;
    margin: 0;
    max-width: 15.63rem;
    width: 100%;
}
.demo__title__text {
    bottom: 2px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}
[demo-viewer] .button svg {
    margin-right: 0.4375rem;
}
[mode="grid"] .demo__card {
    display: flex;
    flex-direction: column;
    overflow: visible;
}
[mode="grid"] .demo__title--dummy {
    color: var(--background-dark);
    color: var(--background-dark);
    font-weight: 600;
}

  /* Grid Mode : Hover */
[demo-viewer] zg-body zg-row:hover,
  [template] [demo-viewer] zg-body zg-row:hover {
    background: var(--sidebar-item-hover);
    background: var(--sidebar-item-hover);
}
[demo-viewer] zg-body zg-row:hover .demo__card--dummy {
    align-items: center;
    justify-content: center;
}
[demo-viewer] zg-row:hover .demo__image__wrapper {
    background-color: transparent;
}
[mode="grid"] .grid-hide,
  [mode="list"] .list-hide,
  [demo-viewer] zg-body zg-row:hover .hover-hide,
  [demo-viewer] zg-body zg-row:not(:hover) .not-hover-hide {
    height: 0 !important;
    margin: 0;
    opacity: 0 !important;
    padding: 0;
    width: 0;
    max-width: 0;
}
[demo-viewer] zg-body zg-row:hover .hover-hide {
    flex: 0 !important;
}
[demo-viewer] .el-dropdown:hover,
  [demo-viewer] .el-dropdown.active {
    background: var(--color-primary-8);
    background: var(--color-primary-8);
}
[demo-viewer] .el-dropdown:hover svg.demo__icon,
  [demo-viewer] .el-dropdown.active svg.demo__icon {
    fill: #fff !important;
}

  /* List Mode */
.button-container,
  .demo__header__left--list,
  .tag-container {
    display: flex;
}
.button-container {
    align-items: center;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.tag-container {
    margin-right: 2.375rem;
}
.tag-button.is-link {
    margin-left: 0.875rem;
    padding: 0;
    position: relative;
    color: var(--color-primary-3);
    color: var(--color-primary-3);
    top: 3px;
}
  /* override parent styling for hoverstate */
.tag-button.is-link:hover { color: var(--color-primary-3); color: var(--color-primary-3);
}
[demo-viewer] .tag {
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: var(--color-greyscale-4);
    background: var(--color-greyscale-4);
    color: var(--color-greyscale-2);
    color: var(--color-greyscale-2);
}
[mode="list"] .demo__body,
  [mode="list"] .demo__header__left,
  [mode="grid"] .demo__header__left--list {
    height: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 0;
}
[mode="list"] .demo__card {
    padding: 0 1rem;
    width: 100%;
}
[mode="list"] .demo__date {
    font-size: 0.75rem;
    white-space: nowrap;
    width: 100%
}
[mode="list"] .demo__header {
    height: 100%;
}
[mode="grid"] .demo__header__left--list {
    flex: 1;
    justify-content: flex-end;
}
[mode="grid"] .demo__header__right {
    max-width: calc(100% - 2rem);
}
[mode="list"] .demo__header__left--list {
    max-width: 40%;
}
[mode="list"] .demo__header__right {
    display: flex;
    max-width: 65%;
}
[mode="list"] .demo__header__right--dummy {
    max-width: 100%;
}
[mode="list"] .demo__header__right h3,
  [mode="list"] .demo__header__right h4 {
    margin-right: 1.125rem;
}
[mode="list"] .demo__icon--open {
    fill: var(--color-primary-4);
    fill: var(--color-primary-4);
}
[mode="list"] .demo__icon__svg {
    fill: var(--color-grayscale-10);
    fill: var(--color-grayscale-10);
}
[mode="list"] [demo-viewer] zg-cell {
    height: 100%;
}
[mode="list"] [demo-viewer] zg-cell > div {
    display: flex;
}
[mode="list"] .demo__title--dummy {
    max-width: inherit;
}
[mode="list"] .demo__title__text--dummy {
    overflow: visible;
}
[mode="list"]  .demo__title__text--emphasize {
    font-weight: bolder;
    text-decoration: underline;
}
  /* List Mode : Hover */
[mode="list"] [demo-viewer] zg-row:hover .demo__button {
    opacity: 1;
    pointer-events: all;
}
[mode="list"] [demo-viewer] zg-row:hover .demo__title__text {
    text-decoration: none;
}

  /* Template */
[demo-viewer] .demo__description {
    color: var(--color-text);
    color: var(--color-text);
    font-size: 0.75rem;
    font-weight: 400;
    height: 2.25rem;
    letter-spacing: 0.02rem;
    overflow: hidden;
    position: relative;
}
[mode="grid"] .demo__description:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 1.13rem;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--background-light) 50%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--background-light) 50%);
}
[mode="grid"] zg-cell {
    left: 0;
    overflow: visible;
    position: relative;
}
[mode="grid"] zg-row:hover .demo__description:after {
    background: linear-gradient(to right, rgba(43, 190, 238, 0), var(--sidebar-item-hover) 50%);
    background: linear-gradient(to right, rgba(43, 190, 238, 0), var(--sidebar-item-hover) 50%);
}
[template] .demo__header {
    min-height: 3.813rem;
}
[template][mode="list"] .demo__header {
    height: 2.938rem;
    min-height: 2.938rem;
}
[template] .demo__image__default::after {
    background-image: url('../assets/images/demoTemplates/basic.png');
}
[template] .demo__image__wrapper {
    background-color: transparent;
}
[mode="list"] [demo-viewer] .demo__description {
    flex: 1;
    height: 100%;
    margin-right: 3.25rem;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
[template] [demo-viewer] zg-body zg-row {
    background: var(--background-light);
    background: var(--background-light);
    border: 1px solid #d8d8d8;
    -o-border-image: url('../assets/images/dashed-border.png') 1 round;
       border-image: url('../assets/images/dashed-border.png') 1 round;
}

  /* animations at the bottom */
@-webkit-keyframes rock {
25% {
      -webkit-transform: rotate(-65deg);
      transform: rotate(-65deg)
}
50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
}
75% {
      -webkit-transform: rotate(65deg);
      transform: rotate(65deg)
}
100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
}
}
@keyframes rock {
25% {
      -webkit-transform: rotate(-65deg);
      transform: rotate(-65deg)
}
50% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
}
75% {
      -webkit-transform: rotate(65deg);
      transform: rotate(65deg)
}
100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
}
}
@media screen and (min-width:400px) {}
.demo__image--dummy {
      height: 85%;
}
@media screen and (min-width:800px) {
[class^="demo-controls__lvl"] {
      flex-direction: row;
      justify-content: space-between;
}
.demo-controls__left {
      flex-direction: row;
      width: 100%;
}
.demo-controls__right {
      width: 50%;
}
.demo-control__item {
      margin-bottom: 0;
}
.demo-control__item--search {
      margin-left: auto;
      max-width: 20rem;
}
.demo-control__item + .demo-control__item,
    .demo-controls__left + .demo-controls__right {
      margin-left: 1rem;
}
[demo-viewer] .el-input input[search] {
      float: right;
      max-width: 23.44rem !important;
      padding-right: 2.5rem;
      width: 100% !important;
}
.demo-controls .el-select {
      width: inherit;
}
[template] [mode="list"] .demo__header__right {
      max-width: 30%;
}
}
@media screen and (max-width: 1000px) {
.tag-container {
      display: none;
}
}
@media screen and (max-width: 1200px) {
[mode="list"] [demo-viewer] .demo__description {
      margin-top: 0.5rem;
}
}
