
  /* Blog */
.blog__feed {
}
.blog__article {
    display: flex;
    margin-bottom: 1rem;
    border: 1px solid #e6e6e6;
}
.blog__image {
    width: 150px;
    height: 100px;
    background-size: cover;
}
.blog__title {
    margin: 0;
}
.blog__content {
    padding: 0.5rem 1rem;
}
.blog__summary {
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.blog__title > a:hover {
    color: var(--link-hover);
    color: var(--link-hover);
}
.blog__image {
    background-position: center center;
}
