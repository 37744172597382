
  /* GLOBAL */
:root {
    --nav-icon-color: #55CDF5;
    --sidebar-icon-primary-color: #EBF5F9;
}

  /* Size: small */
[size="small"] [icon="carot"] {
    bottom: 1px;
    position: relative;
    width: 0.5rem;
}

  /* Position */
  /* [icon="dashboard"],
  [icon="subscriptions"] {
    left: 3px;
    position: relative;
  } */
