
p[data-v-5594fff5] {
    word-break: break-word;
}
.flex[data-v-5594fff5] {
    display: flex;
}
.flex > div[data-v-5594fff5] {
    padding: var(--domain-modal-padding);
    padding: var(--domain-modal-padding);
    width: 50%;
}
.flex > div + div[data-v-5594fff5] {
    border-left: var(--zingnet-border);
    border-left: var(--zingnet-border);
}
section + section[data-v-5594fff5] {
    margin-top: 1.875rem;
}
