
.dialog__icon svg[data-v-2c440920] { width: 1.25rem;
}
.dialog__options[data-v-2c440920] { align-items: center; display: flex; flex: 1; justify-content: center; margin-top: 1.5rem;
}
h2[data-v-2c440920] { font-size: 1.15rem; font-weight: 500; margin: 0; text-align: center;
}
[type="danger"] .dialog__icon[data-v-2c440920], [type="danger"] h2[data-v-2c440920] { color: #f56c6c;
}
[type="success"] .dialog__icon[data-v-2c440920], [type="success"] h2[data-v-2c440920] { color: #07a783;
}
