
[zingnet-date-range] p {
    color: var(--color-primary-gray-visited);
    color: var(--color-primary-gray-visited);
    font-size: 0.9375rem;
    font-style: italic;
    margin: 0 0 0 1.125rem;
}
[zingnet-date-range] {
    align-items: center;
    display: flex;
    margin-top: 1.1875rem;
}
[zingnet-date-range] .el-date-editor {
    margin-left: 1.5rem;
}
