
[authing] { display:none;
}
.app-footer--narrow, .content--narrow { max-width: var(--max-width); max-width: var(--max-width); padding: 0 1rem 1rem 1rem; width: 100%;
}
.background--grid { background-image: url('../../assets/images/bg_grid.png'); background-repeat: no-repeat; background-size: cover;
}
.background--grid_wrap { background: linear-gradient(45deg, #004559, #006379); display: flex; flex-direction: column; min-height: calc(100% - var(--footer-height)); min-height: calc(100% - var(--footer-height));
}
.content,
  .content_wrap,
  .secondary--section { height: 100%;
}
.content_wrapper { min-width: 30rem;
}
[slim] .background--grid_wrap { min-height: 100%;
}
@media screen and (max-width: 472px) {
.content_wrapper { min-width: 100% !important;
}
.app-footer--narrow { padding: 0 0.25rem;
}
}
