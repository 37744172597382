
span[data-v-0e288394], p[data-v-0e288394] {
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    margin: 0;
}
p[data-v-0e288394] {
    text-align: center;
}
.progress[data-v-0e288394] {
    display: flex;
    justify-content: space-between;
}
.progressText[data-v-0e288394] {
    color: var(--color-secondary-blue);
    color: var(--color-secondary-blue);
    font-size: 2.5rem;
    font-weight: 200;
    margin: 0 0.3125rem 1rem 0;
}
.progressText__fail[data-v-0e288394] {
    color: var(--color-primary-danger);
    color: var(--color-primary-danger);
}
.progressText__left[data-v-0e288394] {
    color: var(--color-secondary-blue-active);
    color: var(--color-secondary-blue-active);
}
.progressBar[data-v-0e288394] {
    background-color: var(--color-tertiary-gray);
    background-color: var(--color-tertiary-gray);
    border-radius: var(--zingnet-border-radius);
    border-radius: var(--zingnet-border-radius);
    display: flex;
    height: 1.5rem;
    margin-bottom: 1rem;
    overflow: hidden;
    width: 100%;
}
.progressBar--completed[data-v-0e288394] {
    background-color: var(--color-secondary-blue);
    background-color: var(--color-secondary-blue);
    height: 100%;
    transition: all 0.2s;
}
.progressBar--divider[data-v-0e288394] {
    background-color: #fff;
    height: 100%;
    width: 0.125rem;
}
.progressBar--left[data-v-0e288394] {
    background-color: var(--color-secondary-blue-active);
    background-color: var(--color-secondary-blue-active);
}
