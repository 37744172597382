
:root {
  --footer-margin: 0.9rem;
}
.footer__wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
  height: var(--footer-height);
  height: var(--footer-height);
}
.content__footer {
  display: flex;
  justify-content: space-between;
  background: var(--color-primary-1);
  background: var(--color-primary-1);
  color: white;
  padding: 1rem;
  width: 100%;
  min-height: var(--footer-height);
  min-height: var(--footer-height);
  font-size: 0.8rem;
  z-index: 1500;
}
.content__footer div > div {
  max-height: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
}
.content__footer--right > div {
  justify-content: flex-end;
}
.content__footer a {
  color: var(--nav-icon-color);
  color: var(--nav-icon-color);
  text-decoration: none;
}
.content__footer a:hover,
[footer="secondary"] .content__footer a:hover {
  color: var(--color-tertiary-1);
  color: var(--color-tertiary-1);
}
.content__footer__links a {
  margin: 0 0 0 0.9rem;
  margin: 0 0 0 var(--footer-margin);
}
.content__footer__links svg {
  margin-right: 0.05rem;
  height: 20px;
  width: 20px;
  -webkit-filter: grayscale(100%) sepia(100%) hue-rotate(190deg) saturate(400%);
          filter: grayscale(100%) sepia(100%) hue-rotate(190deg) saturate(400%);
}
.content__footer__links a:hover svg {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
.content__footer__links [icon="zinggrid-logomark-light"] {
  margin-right: 0.35rem;
}
.content__footer__links__separator {
  margin: 0 0.9rem;
  margin: 0 var(--footer-margin);
}

/* Footer on Entry Pages */
[footer="secondary"] .content__footer {
  background: var(--color-reset);
  background: var(--color-reset);
  color: var(--color-greyscale-8);
  color: var(--color-greyscale-8);
}
[footer="secondary"] .content__footer a {
  color: var(--color-primary-1);
  color: var(--color-primary-1);
}
[footer="secondary"] .content__footer__links svg {
  -webkit-filter: grayscale(100%) sepia(100%) hue-rotate(190deg) saturate(400%);
          filter: grayscale(100%) sepia(100%) hue-rotate(190deg) saturate(400%);
}
