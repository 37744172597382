
.editor__resizer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #989898;
    z-index: 1;
}
[editorLayout] {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 0;
    height: 100%;
}
[editorSetting="setting"] {
    margin-top: 20px;
}
[editorSetting="setting__title"] {
    font-weight: 600;
}

  /* COL / ROW */
[editorLayout|="col"] {
    flex-direction: row;
}
[editorLayout|="col"] .editor__code-container{
    flex-direction: column;
}
[editorLayout|="col"] .editor__resizer--code {
    cursor: row-resize;
    height: 10px;
    width: 100%;
}
[editorLayout|="col"] .editor__resizer--preview {
    cursor: col-resize;
    height: 100%;
    width: 10px;
}
[editorLayout|="row"] .editor__resizer--code {
    cursor: col-resize;
    height: 100%;
    width: 10px;
}
[editorLayout|="row"] .editor__resizer--preview {
    cursor: row-resize;
    height: 10px;
    width: 100%;
}
[editorLayout|="col"] .editor__resizer--code .fa-ellipsis-vertical,
  [editorLayout|="col"] .editor__resizer--preview .fa-ellipsis,
  [editorLayout|="row"] .editor__resizer--code .fa-ellipsis,
  [editorLayout|="row"] .editor__resizer--preview .fa-ellipsis-vertical {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

  /* LEFT */
[editorLayout$="left"] {
    flex-direction: row-reverse;
}

  /* RIGHT */
[editorLayout$="right"] {
    flex-direction: row;
}

  /* TOP */
[editorlayout$="top"] {
    flex-direction: column-reverse;
}

  /* LAYOUT DROPDOWN */
[layout-dropdown="externalLink"] {
    margin-left: 0.75rem;
    position: relative;
    bottom: 1px;
}

  /* THEMES: DEFAULT */
[editorTheme="default"] {
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorSecondary: #fff;
    --editorTheme-editorTertiary: var(--color-primary-4);
    --editorTheme-editorTertiary: var(--color-primary-4);
    --editorTheme-primary: #1EBAED;
    --editorTheme-secondary: #78d6f4;
    --editorTheme-tertiary: #e9f8fd;
}

  /* THEMES: DARK */
[editorTheme="dark"] {
    --editorTheme-editorPrimary: #383838;
    --editorTheme-editorSecondary: #1e1e1e;
    --editorTheme-editorTertiary: #6d7698;
    --editorTheme-primary: #94aedf;
    --editorTheme-secondary: #6d7c99;
    --editorTheme-tertiary: #e5edfd;
}

  /* THEMES: COBALT */
[editorTheme="cobalt"] {
    --editorTheme-editorPrimary: #1b364e;
    --editorTheme-editorSecondary: #002240;
    --editorTheme-editorTertiary: #7b937f;
    --editorTheme-primary: #da8b0a;
    --editorTheme-secondary: #b67a13;
    --editorTheme-tertiary: #ffe8c5;
}

  /* THEMES: GITHUB */
[editorTheme="github"] {
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorPrimary: var(--background-mid);
    --editorTheme-editorSecondary: ghostwhite;
    --editorTheme-editorTertiary: #09885a;
    --editorTheme-primary: #0000ff;
    --editorTheme-secondary: #67a7f0;
    --editorTheme-tertiary: #f2f7ff;
}

  /* THEMES: MONOKAI */
[editorTheme="monokai"] {
    --editorTheme-editorPrimary: #4c4b3e;
    --editorTheme-editorSecondary: #272822;
    --editorTheme-editorTertiary: #ab5772;
    --editorTheme-primary: #cdc369;
    --editorTheme-secondary: #928c50;
    --editorTheme-tertiary: #f7f3d1;
}

  /* THEME */
[editorTheme] .editor {
    background: var(--editorTheme-editorSecondary);
    background: var(--editorTheme-editorSecondary);
}
[editorTheme] .editor__code-header {
    background: var(--editorTheme-editorTertiary);
    background: var(--editorTheme-editorTertiary);
}
[editorTheme] .editor__controls,
  [editorTheme] .editor__resizer {
    background: var(--editorTheme-editorPrimary);
    background: var(--editorTheme-editorPrimary);
}
[editorTheme] .editor__controls .el-input__wrapper,
  [editorTheme] .editor__controls .el-input__inner {
    background-color: var(--editorTheme-editorSecondary);
    background-color: var(--editorTheme-editorSecondary);
    box-shadow: 0 0 0 1px var(--editorTheme-editorSecondary) inset;
    box-shadow: 0 0 0 1px var(--editorTheme-editorSecondary) inset;
}
[editorTheme] .el-button--primary,
  [editorTheme] .el-checkbox-button.is-checked .el-checkbox-button__inner {
    background-color: var(--editorTheme-primary);
    background-color: var(--editorTheme-primary);
    border-color: var(--editorTheme-primary);
    border-color: var(--editorTheme-primary);
}
[editorTheme] > .el-button--primary,
  [editorTheme] .el-checkbox-button.is-checked .el-checkbox-button__inner {
    box-shadow: -1px 0 0 0 var(--editorTheme-secondary);
    box-shadow: -1px 0 0 0 var(--editorTheme-secondary);
}
[editorTheme] .el-button:hover,
  [editorTheme] .el-checkbox-button__inner:hover {
    background-color: var(--editorTheme-tertiary);
    background-color: var(--editorTheme-tertiary);;
    border-color: var(--editorTheme-primary);;
    border-color: var(--editorTheme-primary);
    color: var(--editorTheme-secondary);
    color: var(--editorTheme-secondary);
}
[editorTheme] .el-button-group .el-button--primary:first-child {
    border-right-color: var(--editorTheme-secondary);
    border-right-color: var(--editorTheme-secondary);
}
[editorTheme] .el-button-group .el-button--primary:last-child {
    border-left-color: var(--editorTheme-secondary);
    border-left-color: var(--editorTheme-secondary);
}
[editorTheme] .is-link {
    color: var(--editorTheme-editorTertiary);
    color: var(--editorTheme-editorTertiary)
}
[editorTheme] .editor__controls .el-input__wrapper,
  [editorTheme] .el-input input {
    border-color: var(--editorTheme-editorPrimary);
    border-color: var(--editorTheme-editorPrimary);
}
.wrapper[editorTheme] {
    height: calc(100% - var(--footer-height));
    height: calc(100% - var(--footer-height));
}

  /* MEDIA QUERY: ABOVE MOBILE */
@media (max-width: 420px) {
.editor__resizer {
      display: none;
}
}
