
.avatar-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.avatar-upload__image {
  height: 150px;
  width: 150px;
  margin: 0 4rem 1rem 4rem;
  border-radius: 75px;
  background: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
