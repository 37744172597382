
.text-secondary[data-v-946b9176] {
    color: var(--color-greyscale-2);
    color: var(--color-greyscale-2);
    font-size: 0.8rem;
    position: relative;
    top: 0.2rem;
}
.text-secondary--warning[data-v-946b9176] { color: var(--color-fail); color: var(--color-fail);
}
