
[zg-custom-creator][data-v-cd759c06] {
    background: var(--color-primary-gray);
    background: var(--color-primary-gray);
    border-bottom: var(--zingnet-border);
    border-bottom: var(--zingnet-border);
    height: 2rem;
}
[icon="create"][data-v-cd759c06] {
    left: 31px;
    position: relative;
    top: 5px;
}
[icon="create"][data-v-cd759c06]:hover {
    cursor: pointer;
}
