
.zingnetCard .el-button[subtable-back] {
    margin-left: 2rem;
    position: absolute;
    top: 2.3rem;
    z-index: 100;
}
.zingnetCard zg-cell .el-button[subtable-expand] {
    margin: 0;
}
.zingnetCard [data-field-index="subtable"] {
    display: flex;
    justify-content: center;
}
.zingnetCard [page-visitor-select] {
    margin-top: 1rem;
}
.zingnetCard [page-visitor-select],
  .zingnetCard [page-visitor-select] input {
    width: 275px
}
