
.collapse--container {
    border: var(--border);
    border: var(--border);
    border-radius: 10px;
    overflow: hidden;
}
.collapse--container + .collapse--container {
    margin-top: 1rem;
}
.collapse--header,
  .collapse--body > div {
    padding: 0.875rem 1.125rem;
}
.collapse--header {
    align-items: center;
    background: var(--zingnet-color-gray);
    background: var(--zingnet-color-gray);
    display: flex;
    justify-content: space-between;
}
.collapse--header .el-button {
    margin: 0;
}
.collapse--body {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}
.collapse--body.collapse {
    border-top: 0;
    max-height: 0;
}
.collapse--body > div {
    border-top: var(--border);
    border-top: var(--border);
}
.collapse--container h3 {
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.9375rem;
    font-weight: 600;
    margin: 0;
}
