
[support="uploadText"] {
    font-size: 0.8rem;
    margin: 0.25rem;
}
[support].el-button {
    bottom: 3px;
    margin: 0 0.5rem 0 0;
    position:relative;
}
[support="form"] {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}
@media screen and (max-width: 400px) {
[support] .el-form {
      margin-top: 1.5rem;
}
[support] .el-form-item {
      margin-bottom: 10px;
}
[support] .el-form--label-top .el-form-item__label {
      padding: 0;
}
}
