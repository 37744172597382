
[entryContainer] .button--bottom { background: var(--color-tertiary-1); background: var(--color-tertiary-1); border-radius: var(--border-radius); border-radius: var(--border-radius); font-size: 0.8125rem; height: 2.5rem; line-height: 1.5rem; width: 7.813rem;
}
[entryContainer] .button--bottom p { font-weight: 600; margin: auto;
}
[entryContainer] .button--bottom:hover { background: var(--color-tertiary-4); background: var(--color-tertiary-4);
}
[entryContainer] [disabled].button--bottom { background: var(--color-primary-5); background: var(--color-primary-5); border-color: var(--color-primary-5); border-color: var(--color-primary-5); cursor: not-allowed;
}
[modal="true"] {
    background: rgba(0, 0, 0, 0.54902);
    left: 0;
    height: 100vh;
    opacity: 0;
    padding-top: 5rem;
    pointer-events: none;
    position: absolute;
    width: 100vw;
    top: 0;
    z-index: 1000;
}
[modal="true"][visisble] { opacity: 1; pointer-events: all;
}
[modal="true"] [slim][entryContainer] { top: calc(50% - 15rem);
}
[resetPassword] .button--bottom { margin: 0.75rem auto 0; width: 11.25rem;
}
[resetPassword] .button--bottom p { color: var(--color-reset); color: var(--color-reset); font-size: 0.8125rem; margin: auto;
}
[resetPassword="p"] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem;
}
[resetpassword="close-modal"] { cursor: pointer; float: right; opacity: 0; pointer-events: none; position: relative; right: 1rem; top: 0.2rem;
}
[modal="true"] [resetpassword="close-modal"] { opacity: 1; pointer-events: all;
}
[resetPassword="center"] { align-self: center;
}
[resetPassword="center"].center--content-inline { display: flex; margin: auto;
}
[resetPassword="container"] { text-align:center;
}
[resetPassword="container__body"] { flex: 1; padding: 0.5rem 0;
}
[resetPassword="form"] { margin: 1.5rem 0.5rem 0;
}
[resetPassword="close-modal"]:hover svg { fill: var(--color-tertiary-7); fill: var(--color-tertiary-7);
}
[resetPassword] [entryContainer="entry__content"] { padding: 0;
}
[resetPassword] [entryContainer="entry__actions"] { margin: 0.5rem 0 2rem;
}
[resetPassword] .el-form-item__error { top: 75%;
}
