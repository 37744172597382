/* THEME BUILDER */
.hidden { pointer-events:none !important; visibility:hidden !important; }
#builder { background-color:#fff; height:100%; }
#builder [data-row="col-1of2"] { display:flex; margin-bottom:0.75rem; }
#builder [data-row="col-1of2"] .el-tabs {display:flex; flex-basis:50%; margin-right:1rem; width:100%; }
#builder [data-row="col-1of2"] .el-tabs__content { overflow:visible; }
#builder [data-row="col-1of2"] .el-tabs__header { width:2.5rem; }
#builder [data-row="col-1of2"] .el-tabs__nav-wrap.is-top {overflow:visible; -webkit-transform-origin:0 0; transform-origin:0 0; -webkit-transform:rotate(-90deg) translate(-16rem); transform:rotate(-90deg) translate(-16rem); width:50rem; }
#builder [data-row="col-2of2"] { box-shadow:0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04); flex:1; min-height:10rem; z-index:1; }
#builder [data-row="col-2of2"] .el-tabs { height:100%; }
#builder [data-row="col-2of2"] .el-tabs__content { height:calc(100% - 1.25rem); }
#builder [data-row="col-2of2"] .el-tabs__nav.is-top { width:100%; }
#builder [data-row="col-2of2"] #tab-2, #builder [data-row="col-2of2"] #tab-3 { margin-left:auto; }
#builder [data-row="col-2of2"] #tab-2, #builder [data-row="col-2of2"] #tab-3, #builder [data-row="col-2of2"] #tab-4 { padding-left:0; }
#builder [data-row="col-2of2"] #pane-css, #builder [data-row="col-2of2"] #pane-html { height:calc(100% - 1.2rem); }
#builder .el-checkbox[aria-checked="true"] + [zing-theme-builder="toggle-label"] { color:var(--color-tertiary-6); color:var(--color-tertiary-6); }
#builder .el-checkbox + .el-checkbox { margin-left:0; }
#builder .el-checkbox + [zing-theme-builder="toggle-label"] { color:#606266; }
#builder .el-checkbox__input { margin:3px 0 0 12px; }
#builder .el-input-group > .el-input__inner { height:1.75rem; line-height:1.75rem;}
#builder .el-tabs__content { width:100%; }
#builder .el-tabs__header { background-color:#e3ecf6; }
#builder .el-tab-pane { height:calc(100% - 1rem); }
#builder .el-tabs__nav { display:flex; }
#builder #featureCheck { float:right; margin-left:0; }
#builder #featureCheck [zing-theme-builder="toggle-label"] { margin-left:3px; top:6px; }
[data-field].selected [zing-theme-builder="name"] { color:#003749; }
[zing-theme-builder="close"] { cursor:pointer; margin:0 0.5rem; }
[zing-theme-builder="close"]:hover { color:#dc1257; cursor:pointer; margin:0 0.5rem; }
[zing-theme-builder="control-cell"] { display:table; }
[zing-theme-builder="feature-controls"] { display:flex; flex-direction:column; height:100%; overflow:visible; }
[zing-theme-builder="feature-controls"], [zing-theme-builder="theme-controls"] { margin-left:2rem; }
[zing-theme-builder="feature-controls"] .el-checkbox { margin-bottom:0.25rem; width:9rem; }
[zing-theme-builder="item"] { background-color:#fff; display:flex; flex-wrap:wrap; align-items:center; line-height:1; }
[zing-theme-builder="item"] .form-field.selected { display:flex; }
[zing-theme-builder="name"] { margin-bottom:0; }
[zing-theme-builder="row"] { display:flex; align-items:center; }
[zing-theme-builder="toggle-label"] { -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; }
[zing-theme-builder="toggle-check"] {margin-left:1.5rem; width:8rem; }
[zing-theme-builder="toggle-scroll"] { float:right; top:0; right:4px; padding-bottom:5px; }
[zing-theme-builder="toggle-scroll"][active] { display:flex; }
[zing-theme-builder="top-bar"] { color:#fff; background-color:#003749; display:flex; font-size:2rem; font-weight:600; justify-content:space-between; margin:0 0 0.5rem; }
[zing-theme-builder="wrapper"] { margin:0 auto; width:1500px; padding:1rem; }
/* Clicked Element */
[zing-theme-builder="clicked-element"] { z-index:1; position:fixed; visibility:hidden; pointer-events:none; background-color:rgba(255, 165, 0, 0.10196); border:3px dashed orange; box-sizing:border-box; }
[zing-theme-builder="clicked-title"] { border-radius:15px; position:absolute; top:0; left:-70px; pointer-events:auto; white-space:nowrap; color:#fff; font-weight:500; font-size:13px; background:orange; padding:0.5rem 0.75rem; width:175px; text-align:center; -webkit-transform:translate3d(0,-135%,0); transform:translate3d(0,-135%,0); }
[zing-theme-builder="clicked-title"]:after { border-top:solid 15px orange; border-left:solid 10px transparent; border-right:solid 10px transparent; content:''; height:0; left:0; margin:0 0 0 72px; position:absolute; right:0; top:90%; width:0; }
/* Hover Element */
[zing-theme-builder="hover-menu"] { position:absolute; top:-3.34375px; left:-70px; max-width:175px; pointer-events:auto; font-size:13px; z-index:1; }
[zing-theme-builder="hover-button"] { border-bottom:1px solid #fff; display:block; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; background:#5dc1dc; color:#fff; cursor:pointer; padding:0.5rem 0.75rem; text-align:center; width:100%; }
[zing-theme-builder="hover-button"]:hover { background:#31afd4; }
[zing-theme-builder="hover-button"]:first-child { border-top-right-radius:15px; border-top-left-radius:15px; }
[zing-theme-builder="hover-buttons"] { width:175px; }
[zing-theme-builder="hover-toggle"] svg { left:15px; position: absolute; top:10px; }
[zing-theme-builder="hover-toggle"] svg:hover, .hasButtons { cursor:pointer; }
[zing-theme-builder="hover-toggle"] { border:none; color:#fff; transition:display 0.15s; width:100%; }
[zing-theme-builder="hover-title"] { border-radius:15px; position:absolute; top:0; left:-70px; pointer-events:auto; white-space:nowrap; color:#fff; font-weight:500; font-size:13px; background:#5dc1dc; padding:0.5rem 0.75rem; width:175px; text-align:center; -webkit-transform: translate3d(0,-135%,0); transform: translate3d(0,-135%,0); }
[zing-theme-builder="hover-title"]:after { border-top:solid 15px #5dc1dc; border-left:solid 10px transparent; border-right:solid 10px transparent; content:''; height:0; left:0; margin:0 0 0 72px; position:absolute; right:0; top:90%; width:0; }
[zing-theme-builder="hover-title"]:before { background-color:transparent; content:''; height:30px; position:absolute; right:0; width:100%; top:20px; }
[zing-theme-builder="hover-title"][active] { border-top-right-radius:0; border-top-left-radius:0; border-bottom-right-radius:15px; border-bottom-left-radius:15px; }
[zing-theme-builder="hover-title"] svg[data-icon="arrow-down"] { visibility:hidden; }
[zing-theme-builder="hover-title"]:hover, [zing-theme-builder="hover-title"]:hover svg { color:#485871; }
[zing-theme-builder="hover-title"][active] svg[data-icon="arrow-down"] { visibility:visible; }
[zing-theme-builder="hover-title"][active] svg[data-icon="arrow-up"] { visibility:hidden; }
#zing-hover-element { z-index:1; position:fixed; visibility:hidden; pointer-events:none; background-color:rgba(4, 163, 245, 0.2); border:3px dashed #5dc1dc; box-sizing:border-box; }
/* Output - Display */
#builder [aria-controls="pane-css"] ~ #tab-2, #builder [aria-controls="pane-html"] ~ #tab-3 { pointer-events:none; right:100rem; position:absolute; visibility:hidden; transition:none; }
#builder [aria-controls="pane-css"].is-active ~ #tab-2, #builder [aria-controls="pane-html"].is-active ~ #tab-3 { pointer-events:all; right:0; position:relative;; visibility:visible; }
#builder .el-tabs__header.is-top button { width:4rem; }
#builder .fa-file-download:before, #builder .fa-save:before { font-size:1.25rem; }
[zing-theme-builder="display-group"]:first-of-type, [zing-theme-builder="display-group"]:last-of-type { padding-left:1rem; }
[zing-theme-builder="save"] button, [zing-theme-builder="download-output"] button { display:block; border:none; height:1.75rem; margin-left:0.5rem; padding:0.3rem 0.3rem; position:relative; top:0.3rem; }
[zing-theme-builder="output-file"] { display:flex; height:100%; }
[zing-theme-builder="output-preview"] { border: 1px solid #dcdfe6; height:100%; }
[zing-theme-builder="output-preview"] .vue-codemirror { height:100%; }
[zing-theme-builder="output-preview"] .CodeMirror { height:100% !important; }
[zing-theme-builder="preview-container"] { border:1px solid #dcdfe6; box-shadow:0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04); flex-basis:50%; padding:1rem; margin:0; overflow:scroll; }
[zing-theme-builder="preview-container"] label { float:right; margin:0 5px 0.5rem; }
.animate--pulse { -webkit-animation:pulse 0.5s infinite; animation:pulse 0.5s infinite; }
@-webkit-keyframes pulse {
  0% { background-color:transparent; }
  50% { background-color:#e3ecf6; }
  100% { background-color:transparent; }
}
@keyframes pulse {
  0% { background-color:transparent; }
  50% { background-color:#e3ecf6; }
  100% { background-color:transparent; }
}
/* Control List */
[data-field] { position:relative; }
[data-field="checkbox"].selected [type="text"] { background-color:transparent; border:none; box-sizing:border-box; float:left; height:100%; line-height:3rem; padding-left:1rem; width:100%; visibility:visible; }
[data-field="checkbox"].selected [zing-theme-builder="default"] { visibility:hidden; position: absolute; }
[data-field="checkbox"] input { visibility:hidden; width:30px; }
[data-field="checkbox"] > *, [zing-theme-builder="item"] > * { width:100%; height:100%; margin:0; }
[zing-theme-builder="control-cell"] * { display:table-cell; vertical-align:middle; padding:0 0.5rem; text-align:left; }
[zing-theme-builder="controls-header"] { border:1px solid #ababab; border-left:none; display:grid; grid-template-columns:2.5625rem 13rem auto; }
[zing-theme-builder="controls-header"] h3 { border-left:1px solid #ababab; font-size:1.25rem; margin:0; padding:0.5rem; }
[zing-theme-builder="controls-list"] { background-color:#dfe4e6; border:1px solid #ababab; border-top:none; height:calc(100% - 4.5rem); overflow:scroll; overflow-x: hidden; }
[zing-theme-builder="controls-list"] .el-checkbox__input { line-height:3rem; }
[zing-theme-builder="default"] { color:#989898; font-weight:400; font-size:0.95rem; height:100%; position:råelative; width:100%;}
[zing-theme-builder="item"] [data-field="checkbox"] > span { border-left:1px solid #ababab; }
[zing-theme-builder="item"] .form-field [data-field="checkbox"] { display:grid; grid-template-columns:2.5rem 13rem auto; height:100%; }
[zing-theme-builder="item"] input:not([type="checkbox"]) { font-size:0.9rem; width:90%; }
[zing-theme-builder="item"] > .form-field { border-bottom:1px solid #ababab; }
[zing-theme-builder="list"] { height:100%; }
[zing-theme-builder="list"] h2 { border:1px solid #ababab; border-bottom:none; height:3rem; margin:0; padding:0.5rem; text-align:center; }
[zing-theme-builder="list-placeholder"] { color:#626262; display:flex; flex-direction:column; font-size:2.25rem; height:100%; justify-content:center; padding:0 2rem; text-align:center; }
[zing-theme-builder="list-placeholder"] h3 { margin:0; }
[zing-theme-builder="list-placeholder"] svg { margin:1rem auto; }
[zing-theme-builder="toggle-label"] { color:#778c92; font-size:0.9rem; font-weight:500; left:2rem; position:absolute; top:2px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; }
/* Tooltip */
[zing-theme-builder="tooltip"] { background-color:#303133; border-radius:5px; top:0; color:#fff; font-weight:500; left:11rem; padding:0.5rem; position:absolute; text-align:center; visibility:hidden; width:15rem; z-index:100; }
[zing-theme-builder="tooltip"]:after {  border-top:solid 8px transparent; border-right:solid 10px #303133; border-bottom:solid 8px transparent; content:''; position:absolute; right:15rem; top:1rem; }
[zing-theme-builder="tooltip"] li { display:block; }
[zing-theme-builder="tooltip"] li:before { color:#fff; content:"•"; margin-right:0.5rem; }
[zing-theme-builder="tooltip"], .selected [zing-theme-builder="tooltip"]:hover { visibility:hidden; }
[zing-theme-builder="var"] { margin-right:-0.0625rem; line-height:1rem; padding: 0; position:relative; }
[zing-theme-builder="var"]:hover > [zing-theme-builder="tooltip"], #builder .el-checkbox__label:hover > [zing-theme-builder="tooltip"], [zing-theme-builder="tooltip"]:hover { cursor:default; visibility:visible; }
/* Tooltip for features */
#builder [zing-theme-builder="feature-controls"] .el-checkbox__label { width:100%; }
#builder .el-checkbox__label [zing-theme-builder="tooltip"]:after { top:0.6rem; }
#builder .el-checkbox__label [zing-theme-builder="tooltip"] { left:10rem; top:-0.55rem; white-space:normal; }
#builder .el-checkbox__label [zing-theme-builder="tooltip"] a { color:#409EFF; }
#builder .el-checkbox__label [zing-theme-builder="tooltip"] a:hover { color:#66b1ff; }
/* Feature List */
[zing-theme-builder="container"] { display:flex; }
[zing-theme-builder="col-2"] { display:flex; flex-direction:column; width:50%;}
/* Resizer */
[zing-theme-builder="resizer"] { background-color:#dcdfe6; cursor:row-resize; display:flex; height:0.15rem; justify-content:center; position:relative; top:-1px; width:100%; z-index:1; }
[zing-theme-builder="resizer"] div { background-color:#fff; display:flex; height:5px; justify-content:center; width:2.5rem; }
[zing-theme-builder="resizer"] svg { bottom:0.45rem; color:#dcdfe6; position: relative; }
/* Smaller screens */
@media screen and (max-width:1500px) {
  [zing-theme-builder="wrapper"] { width:100%; }
}
@media screen and (max-width:992px) {
  #builder .el-tabs__content { min-height:300px; width:100%; }
  #builder [data-row] .el-tabs { height:31rem!important; }
  #builder [data-row="col-1of2"] { flex-wrap:wrap-reverse; height:auto !important; margin:0; }
  #builder [data-row="col-1of2"] .el-tabs { flex-basis:100%; margin:1rem 0;}
  #builder [data-row="col-1of2"] [zing-theme-builder="preview-container"] { flex-basis:100%; }
  [zing-theme-builder="controls-header"], [zing-theme-builder="item"] .form-field [data-field="checkbox"] { grid-template-columns:2.5rem 40% calc(50% - 2.5rem); }
  [zing-theme-builder="list-placeholder"] svg { -webkit-transform:rotate(-90deg); transform:rotate(-90deg); }
  [zing-theme-builder="output-preview"] .vue-codemirror, [zing-theme-builder="output-preview"] .CodeMirror-scroll { min-height:300px; }
  [zing-theme-builder="preview-container"] { overflow-x:scroll; overflow-y:hidden; }
  [zing-theme-builder="resizer"] { visibility:hidden; }
}
@media screen and (max-width:472px) {
  [zing-theme-builder="container"] { flex-direction:column; }
}
@media screen and (min-width:992px) {
  #builder [data-row="col-1of2"] { min-height:20rem; }
  #builder [data-row="col-2of2"] { margin-top:0.55rem; }
  [zing-theme-builder="builder"], [zing-theme-builder="wrapper"] { display:flex; flex-direction:column; height:100%; }
  #toggleCheck { display: none; }
}