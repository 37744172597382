
[unsuccessful] .button--bottom.button--center[data-v-697b2f0e] { background-color: #06A3F5; margin: 2rem auto 0 auto;
}
[unsuccessful] .button--bottom p[data-v-697b2f0e] { color: var(--color-reset); color: var(--color-reset); font-size: 0.9375rem; line-height: inherit;
}
[unsuccessful] .entry__actions > div[data-v-697b2f0e] { position: relative;
}
[unsuccessful] h1[data-v-697b2f0e] { color: var(--header-color); color: var(--header-color); font-size: 1.375rem; margin: 0 auto 1.5rem; max-width: var(--container-width); max-width: var(--container-width);text-align: center;
}
[unsuccessful] p[data-v-697b2f0e] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem; margin: auto;
}
[unsuccessful="center"][data-v-697b2f0e] { align-self: center;
}
[unsuccessful="center"].center--content-inline[data-v-697b2f0e] { display: flex; margin: auto;
}
[unsuccessful="container"][data-v-697b2f0e] { text-align:center;
}
[unsuccessful="container__body"][data-v-697b2f0e] { flex: 1; padding: 0.5rem 0 1.5rem;
}
[unsuccessful="link"][data-v-697b2f0e] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); font-weight: 900; text-decoration: underline;
}
[unsuccessful="link--unsuccessful" ] a[data-v-697b2f0e] { text-decoration: none;
}
[unsuccessful="link--unsuccessful" ] p[data-v-697b2f0e] { color: var(--color-reset); color: var(--color-reset); font-size: 0.9rem; font-weight: 500; letter-spacing: 1px; margin-right: 0.5rem; text-transform: uppercase;
}
