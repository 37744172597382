
:root {
    --code__header-height: 25px;
}
.action-text { text-transform: uppercase;
}

  /* ASSETS */
[dialog="assets"] .el-select {
    width: 100%;
}
[dialog="assets"] .el-input-icon {
    top: 0;
}
.content {
    width: 100%;
}
.dialog_container {
    display: flex;
    margin: 1.5rem 0;
}
.editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.editor__top {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    min-width: 0;
    overflow: hidden;
}
.editor__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    padding: 0.5rem 0;
    font-size: 1rem;
}
.editor__controls--left {
    display: flex;
    align-items: center;
    flex: 1;
}
.editor__controls--left .el-input {
    font-size: 1rem;
    padding-right: 2rem;
}
.editor__controls--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.editor__controls-toggle {
    display: flex;
    background: var(--color-tertiary-1);
    background: var(--color-tertiary-1);
    color: #FFF;
    border-radius: 10px;
    padding: 0.25rem 1rem;
    width: 90px;
    margin-right: 1rem;
}
.editor__controls ~ .flex {
    height: calc(100% - 40px);
}
.editor__code-container {
    display: flex;
    height: 100%;
    z-index: 200;
}
.editor__code {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.editor__code-header {
    display: flex;
    justify-content: space-between;
    background: var(--color-primary-4);
    background: var(--color-primary-4);
    font-size: 0.8rem;
    color: #FFF;
    padding: 0.2rem 0.5rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    min-height: 25px;
    min-height: var(--code__header-height);
    max-height: 25px;
    max-height: var(--code__header-height);
}
.editor__maximize-icon {
    cursor: pointer;
}
.editor__bottom {
    background: #e6e6e6;
    display: flex;
    flex-direction: column;
}
.editor__bottom-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--color-primary-4);
    background: var(--color-primary-4);
    width: 100%;
    padding: 1rem;
}
.editor__bottom__runOnClick .container {
    align-items: center;
    background:#e6e6e6;
    display: flex;
    height: 100%;
    justify-content: center;
}
.editor__bottom__runOnClick .clickToRun {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.editor__bottom__runOnClick .clickToRun:hover {
    cursor: pointer;
}
.editor__bottom__runOnClick .clickToRun p {
    color: #063747;
    font-size: 1.5rem;
}
.editor__bottom__runOnClick .clickToRun:hover p {
    color: #606266;
}
.editor__bottom__runOnClick .clickToRun svg path {
    fill: #063747;
}
.editor__bottom__runOnClick .clickToRun:hover svg path {
    fill: #606266;
}
.editor__codewrap {
    height: calc(100% - 25px);
    font-size: 1rem;
}
.el-message--error {
    z-index: 10000000 !important;
}
.el-message .el-message__closeBtn {
    top: 6px;
};
[forkDemo] .el-button { 
    float: inherit;
    width: 100%;
}
[forkDemo] .el-dialog__body {
    padding: 0px 45px 30px;
}

  /* Create Demo Dialog Overwrites */
.template__dialog .el-dialog {
    padding-right: 0 !important;
}
.template__dialog .el-dialog__header {
    padding: 2rem 2.3rem 0 !important;
}
.template__dialog .el-tabs__header {
    margin: 0;
    padding: 0 0 0 2.3rem;
}
.template__dialog .el-tabs__active-bar {
    height: 4px;
}
.template__dialog .el-tabs__content {
    background: #F4F4F4;
}
.template__dialog .el-tabs__item {
    font-weight: 400;
    height: 38px;
}
.template__dialog .el-tabs__nav-wrap::after {
    background-color: transparent;
}
.template__dialog .el-tabs--top {
    font-size: 0.875rem;
}
.el-dialog__premium .el-dialog__body {
    padding: 0 45px 30px !important;
}
.el-dialog__premium .el-button {
    float: none !important;
    margin-top: 0.75rem;
}
.preview{
    height: 100%;
    flex: 1;
    width: 100%;
    border: 0;
}
[short].el-dialog{
    padding: 0 3rem 0 0 !important;
    width: 30rem !important;
}
.upload-dialog .el-dialog{
    width: 70% !important;
}
.upload-dialog__files {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
}
.upload-dialog .el-dialog__header {
    padding: 1rem;
    border-bottom: 1px solid #EFEFEF;
}
.upload-dialog .el-dialog__title {
    font-size: 1.2rem;
    color: #303133;
    font-weight: 600;
}
.upload-dialog__heading {
    padding: 1rem 0;
    margin: 0;
}
.upload-dialog .el-dialog__body {
    padding: 0 1rem 1rem 1rem;
}
.upload-dialog__file {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5em;
    border-bottom: 1px solid #EFEFEF;
}
.collapsed_bar {
    height: 100%;
    width: 20px;
}
.editor__alert {
    position: absolute;
    z-index: 1100;
}
.editor__title {
    width: 100%;
    max-width: 500px;
    font-size: 1.25rem;
    font-weight: 300;
    border: 0;
    background: none;
    margin-right: 1rem;
}
.editor__title:focus{
    border-bottom: 1px solid var(--color-tertiary-1);
    border-bottom: 1px solid var(--color-tertiary-1);
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}


/* TEMPLATE DIALOG */
.template__dialog {
  width: 100%;
}
.template__dialog .el-dialog {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 800px;
  height: 70%;
  max-height: 600px;
  flex: 1;
  overflow: hidden;
}
.template__dialog .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: hidden;
  height: 100%;
}
.template__container {
  display : flex;
  padding: 0.5rem 1rem 1rem;
  width: 100%;
  height: 100%;
  min-height: 0;
}
.el-tabs__content {
  display: flex;
  height: 100%;
}
.template__container .el-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.template__container .el-tab-pane {
  display: flex;
  flex: 1;
}
.settings_dialog .el-tab-pane {
  display: flex;
  flex-direction: column;
}
.template__container,
.template__dialog .el-dialog__body {
  padding: 0;
}
.template__button.el-button {
  border-radius: var(--border-radius);
  border-radius: var(--border-radius);
  margin-bottom: 0.25rem;
  margin: auto 0 0.25rem 0;
  width: 4.5rem;
}
.template__description {
  color: #303133;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.35rem 0;
}
.template__list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding: 1.15rem;
  width: 100%;
}
.template__item {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex: 1 0 25%;
  flex-direction: column;
  margin: 0.75rem;
  padding: 0.75rem 1.25rem;
  width: 40%;
}
.template__item:last-child {
  max-height: 15.5rem;
}
.template__item:first-child,
.template__item:nth-child(2) {
  flex: 1 0 40%;
}
.template__title {
  color: #303133;
  font-size: 0.75rem;
  font-weight: bolder;
  margin: 0 0 0.35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1px);
}
.template__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 100%;
  width: 100%;
}
.template__image__wrapper {
  background: #E6E6E6;
  border-radius: 8px;
  height: 5.25rem;
  padding: 0.75rem;
  width: 100%;
}
.template__item:first-child > .template__image__wrapper,
.template__item:nth-child(2) > .template__image__wrapper,
.template__item:last-child > .template__image__wrapper {
  height: 8.25rem;
}
.settings__dialog .el-dialog {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 80%;
  max-width: var(--max-width);
  max-width: var(--max-width);
}
.settings__dialog .el-tab-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1.5rem;
  min-height: 400px;
  flex: 1;
}
.settings__dialog .el-input, .settings__dialog .el-textarea {
  max-width: 400px;
}
.settings__dialog .el-tab-pane .el-button{
  max-height: 45px;
}
.settings__entry {
  margin-bottom: 1.5rem;
}

/* TAGS */
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
.input-new-tag:not(:first-child) {
  margin-left: 10px;
}
.input-new-tag input,
.input-new-tag .el-input__icon {
  line-height: 32px !important;
  height: 32px !important;
}
.demo-description textarea {
  min-height: 70px !important;
}
@media (min-width: 420px) {
.editor__controls {
    padding: 0.8rem;
}
.editor__controls--right {
    justify-content: space-between;
}
.editor__controls--right .el-checkbox-group, .editor__controls--right .el-button {
    margin-left: 10px;
}
.editor__top {
    min-height: 0;
}
}
@media (max-width: 360px) {
.editor__controls--right .el-button-group .el-button--mini,
  .editor__controls--right .el-radio-button--mini .el-radio-button__inner,
  .editor__controls--right .el-dropdown .el-dropdown__icon {
    font-size: 9px;
}
}
@media (max-width: 420px) {
.editor__controls--right {
    justify-content: space-evenly;
    width: 100%;
}

  /* El button mini override */
.el-button, 
  .el-button-group .el-button--mini,
  .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
}
.template__item,
  .template__item:first-child,
  .template__item:nth-child(2) {
    flex: 1 0 40%;
    width: 100%;
}
}
@media (max-width: 770px) {
.action-text {
    display: none;
}
}
.iframe-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}
