
[header-bar="bar"] {
    background: var(--color-secondary-3);
    background: var(--color-secondary-3);
    left: 0;
    max-height: var(--nav-height);
    max-height: var(--nav-height);
    position: absolute;
    right: 0;
    top: 0;
    transition: top .4s cubic-bezier(.175,.885,.32,1.085);
    z-index: 100;
}
[header-bar].show {
    top: var(--nav-height);
    top: var(--nav-height);
}
[header-bar="inner"] {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: center;
    width: auto;
}
[header-bar] [icon="close--circle"] {
    cursor: pointer;
    fill: #fff;
    margin: 9px 1rem;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform-origin: 8px 15px;
            transform-origin: 8px 15px;
}
[header-bar] a {
    color: #fff;
}
