
.content[data-v-7f7ddddf] { text-align: center;
}
[entryContainer] .button--bottom[data-v-7f7ddddf] { background: var(--color-tertiary-1); background: var(--color-tertiary-1); border-radius: var(--border-radius); border-radius: var(--border-radius); font-size: 0.8125rem; height: 2.5rem; line-height: 1.5rem; width: 7.813rem;
}
[entryContainer] .button--bottom p[data-v-7f7ddddf] { font-weight: 600; margin: auto;
}
[entryContainer] .button--bottom[data-v-7f7ddddf]:hover { background: var(--color-tertiary-4); background: var(--color-tertiary-4);
}
[entryContainer] [disabled].button--bottom[data-v-7f7ddddf] { background: var(--color-primary-5); background: var(--color-primary-5); border-color: var(--color-primary-5); border-color: var(--color-primary-5); cursor: not-allowed;
}
[entryContainer] .flex-container[data-v-7f7ddddf] { display: flex; justify-content: space-between;
}
[entryContainer] .link--blue[data-v-7f7ddddf] { display: flex; font-size: 0.8125rem; font-weight: 500; text-decoration: none;
}
[entryContainer] .link--blue[data-v-7f7ddddf]:hover { color: #2d62bd;
}
@media (max-width: 800px) {
.login__container[data-v-7f7ddddf] { margin-top: 0;
}
}

  /* Mobile Overwrites */
@media screen and (max-width:472px) {
.button__text[data-v-7f7ddddf] { margin-left: 0;
}
}
@media screen and (max-height: 900px) {
.signup__container[data-v-7f7ddddf] { margin: 1rem auto;
}
}
@media screen and (min-height: 600px) {
[slim][entryContainer][data-v-7f7ddddf] { position: relative; top: 0;
}
}
