.inner[data-v-185f7a02] {
  font-family: "Avenir", "Nunito Sans", "Lucida Sans Unicode", sans-serif;
  margin: 0 auto;
  padding: 3.35rem 2rem;
  max-width: 1600px;
  box-sizing: border-box;
}
[pricing="text--light"][data-v-185f7a02] {
  color: #707070;
  font-size: 0.8125rem;
}
[pricing*="bold"][data-v-185f7a02] {
  font-weight: bolder;
}
[pricing="box"][data-v-185f7a02] {
  margin: auto;
  max-width: 100%;
  width: 330px;
}
[pricing*="container"][data-v-185f7a02] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 48.44rem;
  padding: 2.5rem 2.5rem 4.063rem;
  width: 100%;
}
[pricing="container--large"][data-v-185f7a02] {
  max-width: 55rem;
  padding: 0;
}
[pricing*="container--medium"][data-v-185f7a02] {
  max-width: 30rem;
  padding: 0;
}
[pricing*="container--small"][data-v-185f7a02] {
  max-width: 24.38rem;
  padding: 2.5rem;
}
[pricing*="container--small"] p[data-v-185f7a02] {
  margin: 0.25rem 0 0;
}
[pricing*="flex-column"][data-v-185f7a02] {
  flex-direction: column;
  justify-content: flex-start;
}
[pricing*="--box"][data-v-185f7a02] {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 8px rgba(114, 114, 114, 0.52941);
}
[pricing*="--center"][data-v-185f7a02] {
  text-align: center;
}
[pricing*="divider"][data-v-185f7a02] {
  border: 0;
}
[pricing="divider--horizontal"][data-v-185f7a02] {
  border-top: 1px solid #d8d8d8;
  margin: 0.6875rem 0;
}
[pricing="divider--vertical"][data-v-185f7a02] {
  border-right: 1px solid #d8d8d8;
  margin: 0;
  opacity: 0;
}
[pricing*="entry-text"][data-v-185f7a02] {
  color: #707070;
  font-size: 0.8125rem;
  margin: 1.5rem 0 0;
  text-align: center;
}
[pricing="h2"][data-v-185f7a02] {
  color: #00394b;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
[pricing*="--highlight"][data-v-185f7a02] {
  color: #0bc098;
}
[pricing~="intro"] h1[data-v-185f7a02] {
  font-size: 1.875rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 1.8rem;
  margin-top: -14px;
}
[pricing*="flex-1"][data-v-185f7a02] {
  flex: 1;
}
[pricing="wrap"][data-v-185f7a02] {
  background: linear-gradient(#eff3f6, #fff);
  background-image: url('../../../assets/images/bg_grid_light.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  height: 100%;
}
[pricing*="--nowrap"][data-v-185f7a02] {
  flex-wrap: nowrap;
}
[pricing="wrap"][data-v-185f7a02],
section[data-v-185f7a02] {
  width: 100%;
}
[pricing~="intro"] h1[data-v-185f7a02] {
  text-align: center;
}
[pricing~="note"][data-v-185f7a02] {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.4;
}
[pricing~="wrap"][data-v-185f7a02] {
  overflow: auto;
}
[pricing~="wrap"] .underline[data-v-185f7a02] {
  border-bottom: 2px solid;
}
[pricing~="wrap"] ul[data-v-185f7a02] {
  margin: 0;
}
[pricing~="wrap"] li[data-v-185f7a02] {
  position: relative;
  margin-bottom: 12px;
  list-style: none;
  color: #07a783;
  font-weight: 500;
}
[pricing~="wrap"] li[data-v-185f7a02]:last-of-type {
  margin: 0;
}
[pricing~="wrap"] li[data-v-185f7a02]:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -20px;
  width: 12px;
  height: 12px;
  display: block;
  background: url(https://storage.googleapis.com/zinggrid-pwa.appspot.com/check-circle.svg);
}
[pricing~="wrap"] li[disabled][data-v-185f7a02] {
  color: #dc1257;
}
[pricing~="wrap"] li[disabled][data-v-185f7a02]:before {
  background: url(https://storage.googleapis.com/zinggrid-pwa.appspot.com/x-circle.svg);
}

/* SECTION: PLANS */
[pricing="features"][data-v-185f7a02] {
  min-height:250px;
}
[pricing~="headline"][data-v-185f7a02] {
  padding-bottom: 10px;
  text-align: center;
}
[pricing~="loading"][data-v-185f7a02] {
  position: relative;
}
[pricing~="loading"] svg[data-v-185f7a02] {
  position: absolute;
}
.no-js [pricing~="container"][data-v-185f7a02],

[pricing~="box"][data-v-185f7a02] {
  margin-bottom: 30px;
  border-radius: 7px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
[pricing~="title"][data-v-185f7a02] {
  margin: 0;
  padding: 0 15px;
  min-height: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.1rem;
  line-height: 1;
  background: var(--color-primary-1);
  background: var(--color-primary-1);
  border-radius: 7px 7px 0 0;
}
[pricing~="tags"][data-v-185f7a02] {
  color: var(--color-primary-1);
  color: var(--color-primary-1);
  margin: 0;
  padding: 0 15px;
  min-height: 35px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  line-height: 1;
  background: #e1e8ee;
}
/* Plan Body Area */
[pricing~="body"][data-v-185f7a02] {
  padding: 15px 15px 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  border-radius: 0 0 7px 7px;
}
/* Price Section */
[pricing~="lead"][data-v-185f7a02] {
  padding: 15px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #e1e8ee;
}
[pricing~="price-box"][data-v-185f7a02] {
  color: var(--color-primary-1);
  color: var(--color-primary-1);
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  line-height: 1;
}
[pricing~="price-value"][data-v-185f7a02] {
  font-size: 3rem;
  font-weight: 600;
}
[pricing~="price-type"][data-v-185f7a02] {
  bottom: 3px;
  margin-left: 5px;
  position: relative;
}
[pricing="text-box"][data-v-185f7a02] {
  background-color: #d4f7ef;
  border: 1px solid #0BC098;
  border-radius: 6px;
  color: #0BC098 !important;
  padding: 8px 16px;
}
[pricing~="toggle-box"][data-v-185f7a02] {
  margin-bottom: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
}
[pricing~="toggle-box"] [pricing*="toggle-"][data-v-185f7a02] {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1;
}
[pricing~="toggle-box"] [pricing~="toggle-perpetual"][data-v-185f7a02] {
  color: #98a4ab;
}
[pricing~="toggle-box"][perpetual="true"] [pricing~="toggle-perpetual"][data-v-185f7a02] {
  color: #04a3f5;
}
[pricing~="toggle-box"] [pricing~="toggle-year"][data-v-185f7a02] {
  color: #04a3f5;
}
[pricing~="toggle-box"][perpetual="true"] [pricing~="toggle-year"][data-v-185f7a02] {
  color: #98a4ab;
}
[pricing~="toggle-box"] [type][data-v-185f7a02] {
  margin: 0 5px;
}
[pricing~="toggle-box"] [type~="toggle-on"][data-v-185f7a02] {
  display: none;
}
[pricing~="toggle-box"][perpetual="true"] [type~="toggle-off"][data-v-185f7a02] {
  display: none;
}
[pricing~="toggle-box"][perpetual="true"] [type~="toggle-on"][data-v-185f7a02] {
  display: block;
}
[pricing~="license"][data-v-185f7a02] {
  color: #04a3f5;
  font-size: 0.9rem;
  font-weight: 500;
}
/* Feature Section (list) */
[pricing~="features"][data-v-185f7a02] {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.4;
}
[pricing~="features"] ul[data-v-185f7a02] {
  margin: 0;
  padding-left: 30px;
  max-width: 80%;
}
[pricing~="features"] p[data-v-185f7a02] {
  margin: 25px 0 -10px;
  padding: 25px 0 0;
  text-align: center;
  border-top: 1px solid #e1e8ee;
}
[pricing*="primary"][data-v-185f7a02] {
  color: var(--color-primary-1);
  color: var(--color-primary-1);
}

/* @MEDIA */
@media screen and (min-width: 508px) {
[pricing~="box"][data-v-185f7a02] {
    display: flex;
    flex-direction: column;
    flex-basis: 47%;
}
[pricing="container--large"][data-v-185f7a02],
  [pricing="container--medium--box"][data-v-185f7a02] {
    padding: 2.5rem 2.5rem 4.063rem;
}
[pricing*="flex-1"][data-v-185f7a02] {
    flex: 1;
    min-width: 300px;
}
}
@media screen and (min-width: 800px) {
[pricing*="container"][data-v-185f7a02] {
    flex-wrap: nowrap;
}
[pricing*="container"] + [pricing*="container"][data-v-185f7a02] {
    margin-left: 2rem;
}
[pricing="divider--vertical"][data-v-185f7a02] {
    margin: 0 2rem;
    opacity: 1;
}
[pricing~="title"][data-v-185f7a02] {
    min-height: 70px;
}
[pricing~="features"][data-v-185f7a02] {
    font-size: 0.8rem;
}

  /* SECTION: SUPPORT */
[pricing~="support-box"][data-v-185f7a02] {
    display: flex;
}
[pricing~="support-box"] [data-card~="split-col"][intro][data-v-185f7a02] {
    max-width: 215px;
}
[pricing~="split-list"][data-v-185f7a02] {
    display: flex;
    justify-content: space-between;
}
[pricing~="split-list"] ul[data-v-185f7a02] {
    flex-basis: 47%;
}
[pricing~="split-list"] ul + ul[data-v-185f7a02] {
    margin-top: 0;
}
}
@media screen and (min-width: 900px) {
[pricing~="features"] p > strong[data-v-185f7a02] {
    max-width: 60%;
    display: inline-block;
}
}
@media screen and (min-width: 1200px) {
[pricing~="features"][data-v-185f7a02] {
    font-size: 0.8rem;
}
}