
[button="false"] [entryContainer="entry__footer"][data-v-0d13f764] { align-content: center; border-top: 1px solid var(--color-greyscale-9); border-top: 1px solid var(--color-greyscale-9); display:flex; height: 5rem; width: 100%;
}
.content[data-v-0d13f764] { text-align: center;
}
[form="false"][data-v-0d13f764] { text-align: center;
}
[form="false"] [entryContainer="entry__content"][data-v-0d13f764] { padding: 0;
}
[form="false"] [entryContainer="entry__content__inner"][data-v-0d13f764] { padding: 0 1rem 1.5rem;
}
[form="false"] h1[data-v-0d13f764] { font-size: 1.375rem;
}
[form="false"] p[data-v-0d13f764] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); font-size: 0.85rem; line-height: 1.25rem; margin: auto;
}
.login__container[data-v-0d13f764] { background: white; padding: 3rem 3rem 5rem; border-radius: var(--border-rounded); border-radius: var(--border-rounded);
}
.p--terms[data-v-0d13f764]{ margin: 2rem 0 1rem; text-decoration: none;
}
[signupFeatures="features__header"][data-v-0d13f764] { color: var(--color-reset); color: var(--color-reset); font-size: 1.563rem;  margin: 0 0 3rem 0;
}
.signin__link[data-v-0d13f764] { color: var(--color-tertiary-6); color: var(--color-tertiary-6); cursor: pointer; font-weight: 600;
}
.signin__link[data-v-0d13f764]:hover { color: var(--color-tertiary-2); color: var(--color-tertiary-2);
}
.signin__p[data-v-0d13f764] { color: var(--header-color); color: var(--header-color); font-size: 0.85rem; margin-bottom: 0;
}
.social-entry[data-v-0d13f764] { align-items: center; display: flex; flex-direction: column; height: 100%; justify-content: center; width: 100%;
}
.social-entry h1[data-v-0d13f764] { font-size: 3rem; margin: 1rem;
}
.social-entry p[data-v-0d13f764] { margin: 2rem;
}
.text--gray[data-v-0d13f764] { color: var(--color-greyscale-8); color: var(--color-greyscale-8); text-decoration: none;
}

  /* Mobile Overwrites */
@media screen and (max-width:400px) {
[entryContainer=""][data-v-0d13f764] { padding: 0;
}
[entryContainer] .flex-container[data-v-0d13f764] { align-items: center; flex-direction: column; justify-content: normal;
}
}
@media screen and (max-width:472px) {
[entryContainer] .features__header[data-v-0d13f764] { margin: 0 0 2rem;
}
[entryContainer="header__logo"][data-v-0d13f764] { margin-bottom: 1.5rem;
}
}
@media screen and (max-width: 800px) {
.content__wrap[data-v-0d13f764] { margin: 30% 1rem;
}
[entryContainer="entry__container"][data-v-0d13f764] { margin-top: 0;
}
[entryContainer="entry__header"][data-v-0d13f764], [entryContainer="entry__content"][data-v-0d13f764] { width: var(--container-width--slim); width: var(--container-width--slim);
}
}
@media screen and (max-height: 900px) {
[entryContainer="entry__actions"][data-v-0d13f764] { margin: 0;
}
[entryContainer="entry__content"][data-v-0d13f764] { padding: 0.75rem 0 0;
}
}
