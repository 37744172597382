
footer[data-v-0b6b80ea] {
    display: flex;
    justify-content: flex-end;
}
pre[data-v-0b6b80ea] {
    background-color: #f4f9fb;
    border: 1px solid #d6e1e6;
    border-radius: 4px;
    margin-bottom: 1.25rem;
    padding: 18px 24px;
    white-space: pre-wrap;
    word-wrap: break-word;
}
section[data-v-0b6b80ea] {
    padding: 2rem;
}
textarea[data-v-0b6b80ea] {
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
@media screen and (min-width: 472px) {
section[data-v-0b6b80ea] {
      padding: 2rem 3rem;
}
}
