
.nav__logo [icon="zingsoft-logomark-light"] {
    display: flex;
}
.dropdown--container { display: flex; align-items: center;padding: 10px;
}
.dropdown--container svg { width:122px; height:37px;
}
  /* MOBILE STYLING  */
.el-menu-item {
    text-decoration: none;
}
.nav__alert,
  .nav__alert--no-badge {
    bottom: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
    right: 1.5rem;
}
.nav__alert svg,
  .nav__alert--no-badge {
    height: 1.05rem;
}
.nav__alert .el-badge__content {
    border: 0 !important;
    font-size: 10px !important;
    height: 15px !important;
    line-height: 12px !important;
    padding: 1px 4px !important;
    right: 5px !important;
    top: 8px !important;
}
.nav__alert--no-badge a {
    color: #fff;
}
.nav__wrap--mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    top: 68px;
    width: 100%;
    height: calc(100% - 68px);
    background: #FFF;
    border-top: 1px solid #EFEFEF;
    padding: 1.5rem;
    z-index: 999;
}
.nav__name--mobile {
    font-size: 1.5rem;
}
.nav__mobile-icon {
    cursor: pointer;
    font-size: 0.9375rem;
    letter-spacing: 0.03rem;
}
.nav__mobile-icon .nav__alert {
    position: static;
    position: initial;
    right: 0;
}
.nav__mobile__text {
    display: flex;
    padding: 0 0.25rem;
}
.nav__header--mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 1rem;
}
.nav__content--mobile {
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: flex-end;
}
.nav__icon--angle {
    margin-left: 0.5rem;
}
.nav__link--mobile {
    font-size: 1.3rem;
    list-style-type: none;
    text-align: right;
    padding: 1rem 0;
    cursor: pointer;
}
.nav__link--mobile a {
    text-decoration: none;
}
.nav__link--mobile.router-link-exact-active {
    font-weight: 600;
}
.nav__link--mobile .fa-users.fa-w-20 { width: 2.25rem;
}
.el-dropdown-menu .fa-users {
    margin: 0 -0.25rem 0 0.25rem;
    width: 1rem;
}
.nav .el-icon-arrow-down.el-icon--right {
    display: none;
}
.nav__avatar--mobile {
    height: 1.375rem;
    width: 1.375rem;
    border-radius: 1.375rem;
    margin: 0 0.8125rem 0 0;
    font-weight: 500;
}
.nav {
    width: 100%;
    background: #fff;
    color: var(--color-primary-1);
    color: var(--color-primary-1);
    box-shadow: 1px 2px 8px rgba(49, 49, 49, 0.28);
    overflow: hidden;
    z-index: 150;
}
.nav__item-container {
    align-items: center;
    display: flex;
}
.nav__item {
    color: #fff;
    font-size: 0.8125rem;
    font-weight: 600;
    padding: 0 1.5rem;
    position: relative;
    text-decoration: none;
}
.nav__item--padding {
    padding: 0 1.5rem 0 1rem;
}
.nav__item.router-link-exact-active {
    background: var(--color-primary-7);
    background: var(--color-primary-7);
}
.nav__wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    background: #fff;
}
.nav__wrap--main {
    background: var(--background-dark);
    background: var(--background-dark);
    line-height: var(--nav-height);
    line-height: var(--nav-height);
    height: var(--nav-height);
    height: var(--nav-height);
    font-size: 1.5rem;
    color: #fff;
}
.nav__content {
    display: flex;
    margin: 0;
}
.nav__content--main {
    justify-content: space-between;
    width: 100%;
}
.nav__content.nav__content--wide {
    padding: 0px;
}
.nav__avatar {
    height: 1.375rem;
    bottom: 2px;
    border-radius: 30px;
    font-weight: 500;
    margin: 0 0.2rem;
    position: relative;
    right:10px;
}
.nav__avatar img {
    box-shadow: var(--box-shadow-card);
    box-shadow: var(--box-shadow-card);
    margin-right: 0.5rem;
    top: 1px;
}
.nav__logo > a {
    display: flex;
}
.nav__logo {
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    margin-left: var(--sidebar-primary-width);
    margin-left: var(--sidebar-primary-width);
    width: 9rem;
}
.nav__item__icon {
    margin: 0 0.875rem 0 0;
}
.nav__link {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0.3rem;
    margin-right: 3rem;
    cursor: pointer;
}
.nav__link a {
    color: white;
    text-decoration: none;
}
.nav__link.router-link-exact-active:after, .nav__link:hover:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: var(--color-tertiary-1);
    background: var(--color-tertiary-1);
}
.nav .el-dropdown {
    padding: 1rem;
}
.nav .el-dropdown.active {
    background: var(--color-primary-8);
    background: var(--color-primary-8);
}
.el-dropdown-link {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FFF;
    font-size: 0.9rem;
    font-weight: normal;
}
.el-dropdown-link__text {
    margin-left: 0.25rem;
    position: relative;
    top: 1px;
}
.el-dropdown-menu__item:hover {
    background-color: var(--color-primary-8) !important;
    background-color: var(--color-primary-8) !important;
}
.el-dropdown-menu__item:first-of-type {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
.el-dropdown-menu__item:last-of-type {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.el-dropdown-menu__item:first-of-type:hover ~ .popper__arrow::after {
    border-bottom-color: var(--color-primary-8) !important;
    border-bottom-color: var(--color-primary-8) !important;
}
.el-icon-arrow-down {
    font-size: 12px;
}
.el-menu {
    border: 0 !important;
}


  /* el-dropdown overwrites */
.el-dropdown-menu {
    padding: 0 !important;
}
.nav .el-dropdown-menu {
    top: 1.75rem !important;
}
.nav__menu-item {
    align-items: center;
    border-bottom: 1px solid #D8D8D8 !important;
    color: var(--color-primary-7) !important;
    color: var(--color-primary-7) !important;
    display: flex;
    font-size: 0.9375rem !important;
    font-weight: 600 !important;
    letter-spacing: 0.03rem !important;
    padding: 5px 20px !important;
}
.nav__menu-item:first-of-type {
    border-top-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}
.nav__menu-item:last-of-type {
    border-bottom: 0 !important;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}
.nav__menu-item:hover {
    color: #fff !important;
    background: var(--color-primary-8) !important;
    background: var(--color-primary-8) !important;
}
.nav__menu-item:hover svg {
    fill: #fff;
}
.nav__menu-item--secondary {
    color: var(--color-tertiary-7) !important;
    color: var(--color-tertiary-7) !important;
    background: #ECF8FF !important;
}
.nav__menu-item--highlight {
    background: var(--color-primary-7) !important;
    background: var(--color-primary-7) !important;
    color: #fff !important;
}
.nav__menu-item--highlight ~ .popper__arrow::after {
    border-bottom: 5px solid var(--color-primary-7) !important;
    border-bottom: 5px solid var(--color-primary-7) !important;
}
.nav__menu-item:first-of-type:hover ~ .popper__arrow::after {
    border-bottom: 5px solid var(--color-primary-8) !important;
    border-bottom: 5px solid var(--color-primary-8) !important;
}
.nav__menu-item__icon {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    width: 1rem;
}
.nav__menu-item__icon svg,
  .nav__menu-item__icon .fa-users {
    width: 1rem;
    margin: 0;
}
.nav__logo a {
    text-decoration: none;
}
@media screen and (min-width: 929px) {
.nav .el-dropdown {
      padding: 1.5rem;
}
}
