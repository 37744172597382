
[bubble-count] .sidebar-item__count {
    border-color: #F5F9FC;
    background-color: #F5F9FC;
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0 0.3125rem;
    padding: 3px 10px;
}
[bubble-count] .sidebar-item__count.pos {
    border-color: #DAFFE0;
    background-color: #DAFFE0;
    color: #1E6F2B;
}
[bubble-count] .sidebar-item__count.neg {
    border-color: #FFCDDA;
    background-color: #FFCDDA;
    color: var(--color-primary-danger);
    color: var(--color-primary-danger);
}
.zingnetCard--header [bubble-count] .sidebar-item__count {
    margin: 0 auto 0 2.25rem;
}
