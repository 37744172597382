
.scorecard[data-v-1c64616e] {
    bottom: 0.625rem;
    margin-bottom: 1.25rem;
    position: relative;
    width: 100%;
}
.primary .value[data-v-1c64616e] {
    color: var(--color-secondary-blue);
    color: var(--color-secondary-blue);
}
.secondary .value[data-v-1c64616e] {
    color: var(--color-primary-gray-visited);
    color: var(--color-primary-gray-visited);
}
hr[data-v-1c64616e] {
    border: 0;
    border-bottom: var(--zingnet-border);
    border-bottom: var(--zingnet-border);
    bottom: 0.3rem;
    margin: 0;
    position: relative;
    min-width: 7.5rem;
}
[icon][data-v-1c64616e] {
    margin-right: 0.3125rem;
}
p[data-v-1c64616e] {
    margin: 0;
}
.value[data-v-1c64616e] {
    font-size: 2.5rem;
    font-weight: 200;
    min-height: 60px;
}
.title[data-v-1c64616e] {
    color: var(--color-primary-gray);
    color: var(--color-primary-gray);
    font-size: 0.8125rem;
    font-weight: 400;
    white-space: nowrap;
}
  
