
.editor,
[studio-editor="container"] {
  height: 100%;
  width: 100%;
}
[studio-editor="clipboard"] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
[studio-editor="container"] {
  position: relative;
}
[studio-editor="controls"] {
  opacity: 0.5;
  position: absolute;
  top: -3px;
  right: 15px;
  z-index: 10;
}
[studio-editor="controls"]:hover {
  opacity: 1;
}
[studio-editor="controls"] > * {
  margin-left: 5px;
}
[studio-editor="controls"] .el-button {
  padding: 2px 7px;
}
.studioEditor_popoverCopy {
  min-width: 90px;
  padding: 10px 0;
  text-align: center;
}
