
[groups] section { padding-bottom: 3rem;
}
[groups] .el-tabs__item {
    max-width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0.5rem;
}
.dialog--condense { display: flex; justify-content: center;
}
.dialog--condense .el-dialog { max-width: 30rem !important; padding-right: 0 !important;
}
.dialog--condense .el-dialog__body { padding: 0 30px 2rem !important;
}
.dialog--condense .el-dialog__body > *, .dialog__icon { text-align: center; word-break: break-word;
}
.dialog--condense .el-dialog__header { padding: 1rem 0rem !important;
}
.dialog__controls { display: flex; justify-content: center; flex-wrap: wrap; margin-top: 2rem; width: 100%;
}
.dialog--padded .el-dialog { padding-right: 3rem !important;
}
.dialog--padded .el-dialog__body { padding: 30px 20px !important;
}
.dialog--padded .el-dialog__header { padding: 2rem 3rem !important;
}
