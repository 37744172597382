
.content__header--expanded {
    align-items: center;
    justify-content: center;
    margin: 2rem;
}
.profile__avatar {
    border-radius: 50%;
    height: 7rem;
    margin: 2rem;
}
.profile__info h2 {
    font-size: 1.25rem;
}
.profile__info h3 {
    color: var(--color-primary-2);
    color: var(--color-primary-2);
    font-size: 1rem;
    font-weight: 500;
}
.profile__info p {
    color: var(--color-greyscale-2);
    color: var(--color-greyscale-2);
}
.profile__info h2,
  .profile__info h3,
  .profile__info p {
    margin: 0 0 0.25rem 0;
}
.profile__info svg {
    margin-right: 0.5rem;
}
